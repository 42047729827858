import {
  CreateFileData,
  AppUserFile,
  UpdateFileData,
  EngagementEvent,
  DailyStep,
} from '@liquid-state/uis-client/dist/types';
import { IReduxAction } from '../types';
import {
  ACTIVATE_APP_USER,
  APP_ACCESS_CODE_GENERATION_FAILED,
  APP_USER_ACTIVATED,
  APP_USER_ACTIVATION_FAILED,
  APP_USER_DEACTIVATED,
  APP_USER_DEACTIVATION_FAILED,
  APP_USER_FORM_COMPUTATIONS_LOADED,
  APP_USERS_FORMS_LOADED,
  APP_USERS_LOADED,
  DEACTIVATE_APP_USER,
  DELETE_APP_USERS,
  FETCH_APP_USERS,
  REFRESH_APP_USERS,
  FETCH_APP_USERS_FORMS,
  LOAD_APP_USERS_FORMS,
  FETCH_APP_USERS_FAILED,
  INVITE_APP_USER,
  INVITE_APP_USER_FAILED,
  INVITE_APP_USER_SUCCESS,
  LOAD_APP_USERS,
  RESEND_APP_USER_INVITE,
  RESEND_APP_USER_INVITE_FAILED,
  RESEND_APP_USER_INVITE_SUCCESS,
  APP_USER_SUBMIT_FORM,
  IAppUser,
  IFormSubmission,
  APP_USER_SUBMIT_FORM_SUCCESS,
  APP_USER_SUBMIT_FORM_FAILED,
  UPDATE_APP_USER,
  UPDATE_APP_USER_SUCCESS,
  UPDATE_APP_USER_FAILED,
  IUISUserProfile,
  IFormComputation,
  FETCH_APP_USER_FILES,
  FETCH_APP_USER_FILES_SUCCESS,
  FETCH_APP_USER_FILES_FAILED,
  CREATE_APP_USER_FILE,
  CREATE_APP_USER_FILE_SUCCESS,
  CREATE_APP_USER_FILE_FAILED,
  UPDATE_APP_USER_FILE,
  UPDATE_APP_USER_FILE_SUCCESS,
  UPDATE_APP_USER_FILE_FAILED,
  REMOVE_APP_USER_FILE,
  REMOVE_APP_USER_FILE_SUCCESS,
  REMOVE_APP_USER_FILE_FAILED,
  GET_APP_USER_FILE_DOWNLOAD_URL,
  GET_APP_USER_FILE_DOWNLOAD_URL_SUCCESS,
  GET_APP_USER_FILE_DOWNLOAD_URL_FAILED,
  CLEAR_APP_USER_FILE_DOWNLOAD_URL,
  FETCH_APP_USER_ENGAGEMENTS,
  FETCH_APP_USER_ENGAGEMENTS_SUCCESS,
  FETCH_APP_USER_ENGAGEMENTS_FAILED,
  FETCH_APP_USER_DAILY_STEPS,
  FETCH_APP_USER_DAILY_STEPS_SUCCESS,
  FETCH_APP_USER_DAILY_STEPS_FAILED,
  FETCH_APP_USER_EXTERNAL_FORM_SUBMISSIONS_FORMS,
  ADD_APP_USER_VIEWED_CONTENT_ID,
} from './types';

export interface IFetchAppUsers extends IReduxAction<typeof FETCH_APP_USERS> {}
export const fetchAppUsers = (): IFetchAppUsers => ({
  type: FETCH_APP_USERS,
  payload: {},
});

export interface IRefreshAppUsers extends IReduxAction<typeof REFRESH_APP_USERS> {}
export const refreshAppUsers = (): IRefreshAppUsers => ({
  type: REFRESH_APP_USERS,
  payload: {},
});

export interface ILoadAppUsers extends IReduxAction<typeof LOAD_APP_USERS> {}
export const loadAppUsers = (): ILoadAppUsers => ({
  type: LOAD_APP_USERS,
  payload: {},
});

export interface IFetchAppUsersFailed extends IReduxAction<typeof FETCH_APP_USERS_FAILED> {}
export const fetchAppUsersFailed = (): IFetchAppUsersFailed => ({
  type: FETCH_APP_USERS_FAILED,
  payload: {},
});

export interface IUpdateAppUser
  extends IReduxAction<typeof UPDATE_APP_USER, { appUserId: string; profile: IUISUserProfile }> {}
export const updateAppUser = (appUserId: string, profile: IUISUserProfile): IUpdateAppUser => ({
  type: UPDATE_APP_USER,
  payload: { appUserId, profile },
});

export interface IUpdateAppUserSuccess
  extends IReduxAction<typeof UPDATE_APP_USER_SUCCESS, { appUserId: string; appUser: IAppUser }> {}
export const updateAppUserSuccess = (
  appUserId: string,
  appUser: IAppUser,
): IUpdateAppUserSuccess => ({
  type: UPDATE_APP_USER_SUCCESS,
  payload: { appUserId, appUser },
});

export interface IUpdateAppUserFailed extends IReduxAction<typeof UPDATE_APP_USER_FAILED, {}> {}
export const updateAppUserFailed = (): IUpdateAppUserFailed => ({
  type: UPDATE_APP_USER_FAILED,
  payload: {},
});

export interface IDeleteAppUsers
  extends IReduxAction<typeof DELETE_APP_USERS, { appUserIds: string[] }> {}
export const deleteAppUsers = (appUserIds: string[]): IDeleteAppUsers => ({
  type: DELETE_APP_USERS,
  payload: { appUserIds },
});

export interface IAppUsersLoaded
  extends IReduxAction<typeof APP_USERS_LOADED, { appUsers: IAppUser[] }> {}
export const appUsersLoaded = (appUsers: IAppUser[]): IAppUsersLoaded => ({
  type: APP_USERS_LOADED,
  payload: {
    appUsers,
  },
});

export interface IFetchAppUsersForms
  extends IReduxAction<typeof FETCH_APP_USERS_FORMS, { appUserUISId: string }> {}
export const fetchAppUsersForms = (appUserUISId: string): IFetchAppUsersForms => ({
  type: FETCH_APP_USERS_FORMS,
  payload: { appUserUISId },
});

export interface ILoadAppUserForms extends IReduxAction<typeof LOAD_APP_USERS_FORMS> {}
export const loadAppUserForms = (): ILoadAppUserForms => ({
  type: LOAD_APP_USERS_FORMS,
  payload: {},
});

export interface IAppUsersFormsLoaded
  extends IReduxAction<
    typeof APP_USERS_FORMS_LOADED,
    { appUserUISId: string; formSubmissions: IFormSubmission[] }
  > {}
export const appUsersFormsLoaded = (
  appUserUISId: string,
  formSubmissions: IFormSubmission[],
): IAppUsersFormsLoaded => ({
  type: APP_USERS_FORMS_LOADED,
  payload: { appUserUISId, formSubmissions },
});

export interface IInviteAppUser
  extends IReduxAction<typeof INVITE_APP_USER, { appUser: IAppUser; journeys: any }> {}
export const inviteAppUser = (appUser: IAppUser, journeys: any): IInviteAppUser => ({
  type: INVITE_APP_USER,
  payload: {
    appUser,
    journeys,
  },
});

export interface IInviteAppUserSuccess
  extends IReduxAction<
    typeof INVITE_APP_USER_SUCCESS,
    { id: string; appUser: IAppUser; journeys: any }
  > {}
export const inviteAppUserSuccess = (
  id: string,
  appUser: IAppUser,
  journeys: any,
): IInviteAppUserSuccess => ({
  type: INVITE_APP_USER_SUCCESS,
  payload: {
    id,
    appUser,
    journeys,
  },
});

export interface IInviteAppUserFailed extends IReduxAction<typeof INVITE_APP_USER_FAILED, {}> {}
export const inviteAppUserFailed = (): IInviteAppUserFailed => ({
  type: INVITE_APP_USER_FAILED,
  payload: {},
});

export interface IActivateAppUser
  extends IReduxAction<typeof ACTIVATE_APP_USER, { appUserId: string }> {}
export const activateAppUser = (appUserId: string): IActivateAppUser => ({
  type: ACTIVATE_APP_USER,
  payload: { appUserId },
});

export interface IAppUserActivated
  extends IReduxAction<typeof APP_USER_ACTIVATED, { appUserId: string }> {}
export const appUserActivated = (appUserId: string): IAppUserActivated => ({
  type: APP_USER_ACTIVATED,
  payload: { appUserId },
});

export interface IAppUserActivationFailed
  extends IReduxAction<typeof APP_USER_ACTIVATION_FAILED, { appUserId: string }> {}
export const appUserActivationFailed = (appUserId: string): IAppUserActivationFailed => ({
  type: APP_USER_ACTIVATION_FAILED,
  payload: { appUserId },
});

export interface IDeactivateAppUser
  extends IReduxAction<typeof DEACTIVATE_APP_USER, { appUserId: string }> {}
export const deactivateAppUser = (appUserId: string): IDeactivateAppUser => ({
  type: DEACTIVATE_APP_USER,
  payload: { appUserId },
});

export interface IAppUserDeactivated
  extends IReduxAction<typeof APP_USER_DEACTIVATED, { appUserId: string }> {}
export const appUserDeactivated = (appUserId: string): IAppUserDeactivated => ({
  type: APP_USER_DEACTIVATED,
  payload: { appUserId },
});

export interface IAppUserDeactivationFailed
  extends IReduxAction<typeof APP_USER_DEACTIVATION_FAILED, { appUserId: string }> {}
export const appUserDeactivationFailed = (appUserId: string): IAppUserDeactivationFailed => ({
  type: APP_USER_DEACTIVATION_FAILED,
  payload: { appUserId },
});

export interface IAppAccessCodeGenerationFailed
  extends IReduxAction<typeof APP_ACCESS_CODE_GENERATION_FAILED> {}
export const appAccessCodeGenerationFailed = (): IAppAccessCodeGenerationFailed => ({
  type: APP_ACCESS_CODE_GENERATION_FAILED,
  payload: {},
});

export interface IResendAppUserInvite
  extends IReduxAction<typeof RESEND_APP_USER_INVITE, { appUserId: string }> {}
export const resendAppUserInvite = (appUserId: string): IResendAppUserInvite => ({
  type: RESEND_APP_USER_INVITE,
  payload: { appUserId },
});

export interface IResendAppUserInviteFailed
  extends IReduxAction<typeof RESEND_APP_USER_INVITE_FAILED, {}> {}
export const resendAppUserInviteFailed = (): IResendAppUserInviteFailed => ({
  type: RESEND_APP_USER_INVITE_FAILED,
  payload: {},
});

export interface IResendAppUserInviteSuccess
  extends IReduxAction<typeof RESEND_APP_USER_INVITE_SUCCESS, {}> {}
export const resendAppUserInviteSuccess = (): IResendAppUserInviteSuccess => ({
  type: RESEND_APP_USER_INVITE_SUCCESS,
  payload: {},
});

export interface ISubmitFormAsAppUser
  extends IReduxAction<
    typeof APP_USER_SUBMIT_FORM,
    { formId: string; formData: object; appUserUISId: string; type: string }
  > {}
export const submitFormAsAppUser = (
  formId: string,
  formData: object,
  appUserUISId: string,
  type: string,
): ISubmitFormAsAppUser => ({
  type: APP_USER_SUBMIT_FORM,
  payload: { formId, formData, appUserUISId, type },
});

export interface ISubmitFormAsAppUserSuccess
  extends IReduxAction<
    typeof APP_USER_SUBMIT_FORM_SUCCESS,
    { appUserUISId: string; formSubmissions: IFormSubmission[] }
  > {}
export const submitFormAsAppUserSuccess = (
  appUserUISId: string,
  formSubmissions: IFormSubmission[],
): ISubmitFormAsAppUserSuccess => ({
  type: APP_USER_SUBMIT_FORM_SUCCESS,
  payload: { appUserUISId, formSubmissions },
});

export interface ISubmitFormAsAppUserFailed
  extends IReduxAction<typeof APP_USER_SUBMIT_FORM_FAILED> {}
export const submitFormAsAppUserFailed = (): ISubmitFormAsAppUserFailed => ({
  type: APP_USER_SUBMIT_FORM_FAILED,
  payload: {},
});

export type IFetchFormsForAppUserResults = ReturnType<typeof fetchFormsForAppUserResults>;
export const fetchFormsForAppUserResults = (formSubmissions: { formObjectType: string }[]) => ({
  type: 'appUsers/fetch-app-user-forms-for-results' as const,
  payload: { formSubmissions },
});

export type IClearFormsForAppUserResults = ReturnType<typeof clearFormsForAppUserResults>;
export const clearFormsForAppUserResults = (formIdList: string[]) => ({
  type: 'appUsers/clear-app-user-forms-for-results' as const,
  payload: { formIdList },
});

export interface IAppUserFormComputationsLoaded
  extends IReduxAction<
    typeof APP_USER_FORM_COMPUTATIONS_LOADED,
    { appUserUISId: string; computations: IFormComputation[] }
  > {}
export const appUserFormComputationsLoaded = (
  appUserUISId: string,
  computations: IFormComputation[],
): IAppUserFormComputationsLoaded => ({
  type: APP_USER_FORM_COMPUTATIONS_LOADED,
  payload: { appUserUISId, computations },
});

export type IFetchAppUserFiles = IReduxAction<typeof FETCH_APP_USER_FILES, { appUserId: string }>;
export const fetchAppUserFiles = (appUserId: string): IFetchAppUserFiles => ({
  type: FETCH_APP_USER_FILES,
  payload: { appUserId },
});

export type IFetchAppUserFilesSuccess = IReduxAction<
  typeof FETCH_APP_USER_FILES_SUCCESS,
  { appUserId: string; files: AppUserFile[] }
>;
export const fetchAppUserFilesSuccess = (
  appUserId: string,
  files: AppUserFile[],
): IFetchAppUserFilesSuccess => ({
  type: FETCH_APP_USER_FILES_SUCCESS,
  payload: { appUserId, files },
});

export type IFetchAppUserFilesFailed = IReduxAction<
  typeof FETCH_APP_USER_FILES_FAILED,
  { appUserId: string }
>;
export const fetchAppUserFilesFailed = (appUserId: string): IFetchAppUserFilesFailed => ({
  type: FETCH_APP_USER_FILES_FAILED,
  payload: { appUserId },
});

export type ICreateAppUserFile = IReduxAction<
  typeof CREATE_APP_USER_FILE,
  { appUserId: string; fileDetails: CreateFileData; file: File }
>;
export const createAppUserFile = (
  appUserId: string,
  fileDetails: CreateFileData,
  file: File,
): ICreateAppUserFile => ({
  type: CREATE_APP_USER_FILE,
  payload: { appUserId, fileDetails, file },
});

export type ICreateAppUserFileSuccess = IReduxAction<
  typeof CREATE_APP_USER_FILE_SUCCESS,
  { appUserId: string; file: AppUserFile }
>;
export const createAppUserFileSuccess = (
  appUserId: string,
  file: AppUserFile,
): ICreateAppUserFileSuccess => ({
  type: CREATE_APP_USER_FILE_SUCCESS,
  payload: { appUserId, file },
});

export type ICreateAppUserFileFailed = IReduxAction<
  typeof CREATE_APP_USER_FILE_FAILED,
  { appUserId: string }
>;
export const createAppUserFileFailed = (appUserId: string): ICreateAppUserFileFailed => ({
  type: CREATE_APP_USER_FILE_FAILED,
  payload: { appUserId },
});

export type IUpdateAppUserFile = IReduxAction<
  typeof UPDATE_APP_USER_FILE,
  { appUserId: string; file: AppUserFile; updatedDetails: UpdateFileData }
>;
export const updateAppUserFile = (
  appUserId: string,
  file: AppUserFile,
  updatedDetails: UpdateFileData,
): IUpdateAppUserFile => ({
  type: UPDATE_APP_USER_FILE,
  payload: { appUserId, file, updatedDetails },
});

export type IUpdateAppUserFileSuccess = IReduxAction<
  typeof UPDATE_APP_USER_FILE_SUCCESS,
  { appUserId: string; file: AppUserFile }
>;
export const updateAppUserFileSuccess = (
  appUserId: string,
  file: AppUserFile,
): IUpdateAppUserFileSuccess => ({
  type: UPDATE_APP_USER_FILE_SUCCESS,
  payload: { appUserId, file },
});

export type IUpdateAppUserFileFailed = IReduxAction<
  typeof UPDATE_APP_USER_FILE_FAILED,
  { appUserId: string }
>;
export const updateAppUserFileFailed = (appUserId: string): IUpdateAppUserFileFailed => ({
  type: UPDATE_APP_USER_FILE_FAILED,
  payload: { appUserId },
});

export type IRemoveAppUserFile = IReduxAction<
  typeof REMOVE_APP_USER_FILE,
  { appUserId: string; file: AppUserFile }
>;
export const removeAppUserFile = (appUserId: string, file: AppUserFile): IRemoveAppUserFile => ({
  type: REMOVE_APP_USER_FILE,
  payload: { appUserId, file },
});

export type IRemoveAppUserFileSuccess = IReduxAction<
  typeof REMOVE_APP_USER_FILE_SUCCESS,
  { appUserId: string; fileUuid: string }
>;
export const removeAppUserFileSuccess = (
  appUserId: string,
  fileUuid: string,
): IRemoveAppUserFileSuccess => ({
  type: REMOVE_APP_USER_FILE_SUCCESS,
  payload: { appUserId, fileUuid },
});

export type IRemoveAppUserFileFailed = IReduxAction<
  typeof REMOVE_APP_USER_FILE_FAILED,
  { appUserId: string }
>;
export const removeAppUserFileFailed = (appUserId: string): IRemoveAppUserFileFailed => ({
  type: REMOVE_APP_USER_FILE_FAILED,
  payload: { appUserId },
});

export type IGetAppUserFileDownloadUrl = IReduxAction<
  typeof GET_APP_USER_FILE_DOWNLOAD_URL,
  { appUserId: string; file: AppUserFile }
>;
export const getAppUserFileDownloadUrl = (
  appUserId: string,
  file: AppUserFile,
): IGetAppUserFileDownloadUrl => ({
  type: GET_APP_USER_FILE_DOWNLOAD_URL,
  payload: { appUserId, file },
});

export type IGetAppUserFileDownloadUrlSuccess = IReduxAction<
  typeof GET_APP_USER_FILE_DOWNLOAD_URL_SUCCESS,
  { appUserId: string; url: string }
>;
export const getAppUserFileDownloadUrlSuccess = (
  appUserId: string,
  url: string,
): IGetAppUserFileDownloadUrlSuccess => ({
  type: GET_APP_USER_FILE_DOWNLOAD_URL_SUCCESS,
  payload: { appUserId, url },
});

export type IGetAppUserFileDownloadUrlFailed = IReduxAction<
  typeof GET_APP_USER_FILE_DOWNLOAD_URL_FAILED,
  { appUserId: string }
>;
export const getAppUserFileDownloadUrlFailed = (
  appUserId: string,
): IGetAppUserFileDownloadUrlFailed => ({
  type: GET_APP_USER_FILE_DOWNLOAD_URL_FAILED,
  payload: { appUserId },
});

export type IClearAppUserFileDownloadUrl = IReduxAction<
  typeof CLEAR_APP_USER_FILE_DOWNLOAD_URL,
  { appUserId: string }
>;
export const clearAppUserFileDownloadUrl = (appUserId: string): IClearAppUserFileDownloadUrl => ({
  type: CLEAR_APP_USER_FILE_DOWNLOAD_URL,
  payload: { appUserId },
});

export type IFetchAppUserEngagements = IReduxAction<
  typeof FETCH_APP_USER_ENGAGEMENTS,
  { appUserId: string; page: number; fromTime?: string; toTime?: string }
>;

export const fetchAppUserEngagements = (
  appUserId: string,
  page: number,
  fromTime?: string,
  toTime?: string,
): IFetchAppUserEngagements => ({
  type: FETCH_APP_USER_ENGAGEMENTS,
  payload: { appUserId, page, fromTime, toTime },
});

export type IFetchAppUserEngagementsSuccess = IReduxAction<
  typeof FETCH_APP_USER_ENGAGEMENTS_SUCCESS,
  { appUserId: string; engagements: EngagementEvent[] }
>;

export const fetchAppUserEngagementsSuccess = (
  appUserId: string,
  engagements: EngagementEvent[],
): IFetchAppUserEngagementsSuccess => ({
  type: FETCH_APP_USER_ENGAGEMENTS_SUCCESS,
  payload: { appUserId, engagements },
});

export type IFetchAppUserEngagementsFailed = IReduxAction<
  typeof FETCH_APP_USER_ENGAGEMENTS_FAILED,
  { appUserId: string }
>;

export const fetchAppUserEngagementsFailed = (
  appUserId: string,
): IFetchAppUserEngagementsFailed => ({
  type: FETCH_APP_USER_ENGAGEMENTS_FAILED,
  payload: { appUserId },
});

export type IFetchAppUserDailySteps = IReduxAction<
  typeof FETCH_APP_USER_DAILY_STEPS,
  { appUserId: string; page: number; fromTime?: string; toTime?: string }
>;

export const fetchAppUserDailySteps = (
  appUserId: string,
  page: number,
  fromTime?: string,
  toTime?: string,
): IFetchAppUserDailySteps => ({
  type: FETCH_APP_USER_DAILY_STEPS,
  payload: { appUserId, page, fromTime, toTime },
});

export type IFetchAppUserDailyStepsSuccess = IReduxAction<
  typeof FETCH_APP_USER_DAILY_STEPS_SUCCESS,
  { appUserId: string; dailySteps: DailyStep[] }
>;

export const fetchAppUserDailyStepsSuccess = (
  appUserId: string,
  dailySteps: DailyStep[],
): IFetchAppUserDailyStepsSuccess => ({
  type: FETCH_APP_USER_DAILY_STEPS_SUCCESS,
  payload: { appUserId, dailySteps },
});

export type IFetchAppUserDailyStepsFailed = IReduxAction<
  typeof FETCH_APP_USER_DAILY_STEPS_FAILED,
  { appUserId: string }
>;

export const fetchAppUserDailyStepsFailed = (appUserId: string): IFetchAppUserDailyStepsFailed => ({
  type: FETCH_APP_USER_DAILY_STEPS_FAILED,
  payload: { appUserId },
});

export type IFetchAppUserExternalFormSubmissionForms = IReduxAction<
  typeof FETCH_APP_USER_EXTERNAL_FORM_SUBMISSIONS_FORMS,
  { formSubmissions: IFormSubmission[] }
>;

export const fetchAppUserExternalFormSubmissionForms = (
  formSubmissions: IFormSubmission[],
): IFetchAppUserExternalFormSubmissionForms => ({
  type: FETCH_APP_USER_EXTERNAL_FORM_SUBMISSIONS_FORMS,
  payload: { formSubmissions },
});

export type IAddAppUserViewedContentId = IReduxAction<
  typeof ADD_APP_USER_VIEWED_CONTENT_ID,
  { appUserId: string; contentId: string }
>;
export const addAppUserViewedContentId = (
  appUserId: string,
  contentId: string,
): IAddAppUserViewedContentId => ({
  type: ADD_APP_USER_VIEWED_CONTENT_ID,
  payload: { appUserId, contentId },
});
