import { useSelector } from 'react-redux';

import { selectPathwaysForAppUser } from '@pathways/redux/appUserPathways/reducers';
import { selectAppUserMainPathway } from '@pathways/redux/pathways/reducers';
import { appUserType } from '../../../propTypes';

function PathwayName({ appUser }) {
  const [, pathways] = useSelector(selectPathwaysForAppUser(appUser));
  const [, pathway] = useSelector(
    selectAppUserMainPathway(
      pathways && pathways.length ? pathways.map(({ originalPathway }) => originalPathway) : [],
    ),
  );

  return (pathway && pathway.name) || null;
}

PathwayName.propTypes = {
  appUser: appUserType,
};

export default PathwayName;
