import {
  LOGIN_SUBMITTED,
  LOGIN_FAILED,
  AUTHENTICATION_REQUIRED,
  AUTHENTICATION_SUBMITTED,
  AUTHENTICATION_FAILED,
  LOGIN_REQUIRED,
  SESSION_ESTABLISHED,
  LOGOUT_SUCCESSFUL,
} from './actions';

const initialState = {
  started: false,
  checkingSession: true,
  authenticated: false,
  user: {
    id: null,
    name: '',
    profile: {},
  },
  authentication: {},
  loading: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESSFUL:
    case LOGIN_REQUIRED:
      return {
        ...state,
        checkingSession: false,
        authenticated: false,
        loading: false,
        user: { id: null },
      };

    case AUTHENTICATION_SUBMITTED:
    case LOGIN_SUBMITTED:
      return {
        ...state,
        started: true,
        loading: true,
        error: false,
      };

    case AUTHENTICATION_FAILED:
    case LOGIN_FAILED:
      return {
        ...state,
        started: false,
        loading: false,
        error: true,
      };

    case AUTHENTICATION_REQUIRED:
      return {
        ...state,
        loading: false,
        authentication: {
          phoneNumber: action.payload.phoneNumber,
        },
      };

    case SESSION_ESTABLISHED:
      return {
        ...state,
        started: false,
        checkingSession: false,
        authenticated: true,
        loading: false,
        user: {
          id: action.payload.id,
          name: action.payload.data.name,
          role: action.payload.data.role,
          email: action.payload.data.email,
          profile: action.payload.data.profile,
        },
      };
    default:
      return state;
  }
};

export const isLoading = state => state.login.loading;
export const hasError = state => state.login.error;

export const checkingSession = state => state.login.checkingSession;
export const hasValidSession = state => state.login.authenticated && state.login.user.id;
export const selectDashboardUserHospital = state => {
  if (state.login.user.profile.hospitalId) {
    return {
      hospitalId: state.login.user.profile.hospitalId,
      hospitalName: state.login.user.profile.hospitalName,
      hospitalSlug: state.login.user.profile.hospitalSlug,
    };
  }

  return {};
};
export const selectCurrentDashboardUser = state => state.login.user;
