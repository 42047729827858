import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ACTIVATE_APP_USER,
  DEACTIVATE_APP_USER,
  DELETE_APP_USERS,
  FETCH_APP_USERS_FORMS,
  FETCH_APP_USERS,
  REFRESH_APP_USERS,
  INVITE_APP_USER,
  RESEND_APP_USER_INVITE,
  APP_USER_SUBMIT_FORM,
  UPDATE_APP_USER,
  FETCH_APP_USER_FILES,
  CREATE_APP_USER_FILE,
  UPDATE_APP_USER_FILE,
  REMOVE_APP_USER_FILE,
  GET_APP_USER_FILE_DOWNLOAD_URL,
  FETCH_APP_USER_ENGAGEMENTS,
  FETCH_APP_USER_DAILY_STEPS,
  FETCH_APP_USER_EXTERNAL_FORM_SUBMISSIONS_FORMS,
} from '../types';
import takeFirst from '../../takeFirst';

import { doActivateAppUser, doDeactivateAppUser, doResendAppUserInvite } from './appUserAccess';
import {
  doFetchAppUserForms,
  doFetchAppUsers,
  doFetchAppUserFormsForResults,
  doFetchAppUserEngagements,
  doFetchAppUserDailySteps,
  doFetchAppUserExternalFormSubmissionForms,
} from './fetch';
import { doDeleteAppUsers, doInviteAppUser, doSubmitAppUserForm, doUpdateAppUser } from './manage';
import {
  doFetchAppUserFiles,
  doCreateAppUserFile,
  doUpdateAppUserFile,
  doDeleteAppUserFile,
  doGetAppUserFileDownloadUrl,
} from './appUserFiles';

export default function* root() {
  yield takeLatest('appUsers/fetch-app-user-forms-for-results', doFetchAppUserFormsForResults);
  yield takeLatest(ACTIVATE_APP_USER, doActivateAppUser);
  yield takeLatest(DEACTIVATE_APP_USER, doDeactivateAppUser);
  yield takeLatest(DELETE_APP_USERS, doDeleteAppUsers);
  yield takeFirst(FETCH_APP_USERS, doFetchAppUsers);
  yield takeLatest(REFRESH_APP_USERS, doFetchAppUsers);
  yield takeLatest(FETCH_APP_USERS_FORMS, doFetchAppUserForms);
  yield takeEvery(INVITE_APP_USER, doInviteAppUser);
  yield takeLatest(RESEND_APP_USER_INVITE, doResendAppUserInvite);
  yield takeLatest(APP_USER_SUBMIT_FORM, doSubmitAppUserForm);
  yield takeLatest(UPDATE_APP_USER, doUpdateAppUser);
  yield takeLatest(FETCH_APP_USER_FILES, doFetchAppUserFiles);
  yield takeLatest(CREATE_APP_USER_FILE, doCreateAppUserFile);
  yield takeLatest(UPDATE_APP_USER_FILE, doUpdateAppUserFile);
  yield takeLatest(REMOVE_APP_USER_FILE, doDeleteAppUserFile);
  yield takeLatest(GET_APP_USER_FILE_DOWNLOAD_URL, doGetAppUserFileDownloadUrl);
  yield takeLatest(FETCH_APP_USER_ENGAGEMENTS, doFetchAppUserEngagements);
  yield takeLatest(FETCH_APP_USER_DAILY_STEPS, doFetchAppUserDailySteps);
  yield takeLatest(
    FETCH_APP_USER_EXTERNAL_FORM_SUBMISSIONS_FORMS,
    doFetchAppUserExternalFormSubmissionForms,
  );
}
