import {
  INVALID_MOBILE,
  INVITATION_CODE_SUBMITTED,
  INVITATION_CODE_FAILED,
  INVITATION_CODE_SUCCESS,
  REGISTRATION_SUBMITTED,
  REGISTRATION_SUCCESS,
  UNHANDLED_REGISTRATION_ERROR,
  USERNAME_ALREADY_EXISTS,
} from './types';

export const invalidMobile = error => ({
  type: INVALID_MOBILE,
  payload: {
    error,
  },
});

export const usernameAlreadyExists = error => ({
  type: USERNAME_ALREADY_EXISTS,
  payload: {
    error,
  },
});

export const unhandledRegistrationError = error => ({
  type: UNHANDLED_REGISTRATION_ERROR,
  payload: {
    error,
  },
});

export const invitationCodeSubmitted = ({ invitationCode }) => ({
  type: INVITATION_CODE_SUBMITTED,
  payload: {
    invitationCode,
  },
});

export const invitationSuccess = email => ({
  type: INVITATION_CODE_SUCCESS,
  payload: { email },
});

export const invitationFailed = () => ({
  type: INVITATION_CODE_FAILED,
  payload: {},
});

export const registrationSubmitted = ({
  email,
  password,
  phoneNumber,
  privacyPolicyAgreement,
  termsAndConditionsAgreement,
}) => ({
  type: REGISTRATION_SUBMITTED,
  payload: {
    email,
    password,
    phoneNumber,
    privacyPolicyAgreement,
    termsAndConditionsAgreement,
  },
});

export const registrationSuccess = () => ({
  type: REGISTRATION_SUCCESS,
  payload: {},
});
