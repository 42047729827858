import React from 'react';
import { Icon } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pathway } from '@assets/icons';
import {
  FEATURE_DOCUMENT,
  FEATURE_FORM,
  MESSAGE,
  TRANSITION_NEXT,
  FEATURE_WEBLINK,
} from '@constants';
import { contentTypes, contentTypesList, WEBLINK, WEBSITE, WEB_FORM } from '@utils/contentTypes';

export const STAGE_TRANSITION = 'stage_transition';
export const RULE_EXECUTION = 'rule_execution';
export const FORM_SUBMISSION = 'form_submitted';
export const ADHOC_MESSAGE = 'adhoc_message';
export const UNSUPPORTED_ACTION = 'UNSUPPORTED ACTION';
export const OTHER = 'other';

function filterDocumentTypeEntries(value, record) {
  if (record.data.rule_what_type === FEATURE_DOCUMENT) {
    if (
      value === 'VIDEO' &&
      record.data.rule_what_details.metadata.tags.some(({ term }) => term.toUpperCase() === 'VIDEO')
    ) {
      return true;
    }

    if (
      value === 'DOCUMENT' &&
      !record.data.rule_what_details.metadata.tags.some(
        ({ term }) => term.toUpperCase() === 'VIDEO',
      )
    ) {
      return true;
    }
  }
}

export function filterEntriesByEvent(value, record) {
  if (record.type === RULE_EXECUTION && contentTypesList.includes(value)) {
    filterDocumentTypeEntries(value, record);

    // forms and messages
    return record.data.rule_what_details.metadata.type === value;
  }

  if (value === STAGE_TRANSITION && record.type === value) {
    return true;
  }

  if (record.type === FORM_SUBMISSION && record.type === value) {
    // TODO: handle filtering for form submissions
  }

  return false;
}

function getUIForDocumentTypes(record, t) {
  if (
    record.data.rule_what_details.metadata.tags?.some(({ term }) => term.toUpperCase() === 'VIDEO')
  ) {
    return (
      <>
        <FontAwesomeIcon icon={contentTypes.VIDEO.icon} />{' '}
        {t('cards:JourneyTimeline.events.featureVideo')}
      </>
    );
  }

  return (
    <>
      <FontAwesomeIcon icon={contentTypes.DOCUMENT.icon} />{' '}
      {t('cards:JourneyTimeline.events.featureDocument')}
    </>
  );
}

function getUIForFormTypes(record, t) {
  switch (record.data.rule_what_details.metadata.type) {
    case 'QUESTIONNAIRE':
      return (
        <>
          <FontAwesomeIcon icon={contentTypes.QUESTIONNAIRE.icon} />{' '}
          {t('cards:JourneyTimeline.events.featureQuestionnaire')}
        </>
      );
    case 'TASK':
      return (
        <>
          <FontAwesomeIcon icon={contentTypes.TASK.icon} />{' '}
          {t('cards:JourneyTimeline.events.featureTask')}
        </>
      );
    default:
      return (
        <>
          <FontAwesomeIcon icon={contentTypes.FORM.icon} />{' '}
          {t('cards:JourneyTimeline.events.featureForm')}
        </>
      );
  }
}

function getUIForMessageTypes(record, t) {
  switch (record.data.rule_what_details.metadata.type) {
    case 'ALERT':
      return (
        <>
          <FontAwesomeIcon icon={contentTypes.ALERT.icon} />{' '}
          {t('cards:JourneyTimeline.events.sendAlert')}
        </>
      );
    case 'REMINDER':
      return (
        <>
          <FontAwesomeIcon icon={contentTypes.REMINDER.icon} />{' '}
          {t('cards:JourneyTimeline.events.sendReminder')}
        </>
      );
    default:
      return (
        <>
          <FontAwesomeIcon icon={contentTypes.MESSAGE.icon} />{' '}
          {t('cards:JourneyTimeline.events.sendMessage')}
        </>
      );
  }
}

export function getEventForRuleExecution(record, t) {
  switch (record.data.rule_what_type) {
    case FEATURE_DOCUMENT:
      return getUIForDocumentTypes(record, t);
    case FEATURE_FORM:
      return getUIForFormTypes(record, t);
    case FEATURE_WEBLINK:
      return (
        <>
          <FontAwesomeIcon
            icon={contentTypes[record.data.rule_what_details.type || WEBLINK].icon}
          />{' '}
          {record.data.rule_what_details.type === WEB_FORM
            ? t('cards:JourneyTimeline.events.featureWebForm')
            : null}
          {record.data.rule_what_details.type === WEBSITE
            ? t('cards:JourneyTimeline.events.featureWebsite')
            : null}
          {!record.data.rule_what_details.type
            ? t('cards:JourneyTimeline.events.featureWeblink')
            : null}
        </>
      );
    case MESSAGE:
      return getUIForMessageTypes(record, t);
    case TRANSITION_NEXT:
      return (
        <>
          <Icon component={pathway} /> {t('cards:JourneyTimeline.events.transitionedStage')}
        </>
      );
    default:
      return 'UNKNOWN EVENT';
  }
}
