import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import ContentRef from '@utils/contentRef';

type TranslationString = string;
export type Language = {
  key: string;
  name: TranslationString;
  icon: IconDefinition;
};

export type Owner = {
  key: string;
  name: TranslationString;
  icon: IconDefinition;
};

export type ISetLanguages = ReturnType<typeof setLanguages>;
export const setLanguages = (languages: Language[]) => ({
  type: 'core/setLanguages' as const,
  payload: {
    languages,
  },
});

export type ISetOwners = ReturnType<typeof setOwners>;
export const setOwners = (owners: Owner[]) => ({
  type: 'core/setOwners' as const,
  payload: {
    owners,
  },
});

export type ILoadContent = ReturnType<typeof loadContent>;
export const loadContent = (refs: string[]) => ({
  type: 'core/load-content' as const,
  payload: {
    refs,
  },
});

export type ILoadContentSuccess = ReturnType<typeof loadContentSuccess>;
export const loadContentSuccess = (contentMap: { [key: string]: any }) => {
  type Indexable = { [key: string]: any };
  const documents: Indexable = {},
    messages: Indexable = {},
    forms: Indexable = {},
    weblinks: Indexable = {};
  for (const [key, content] of Object.entries(contentMap)) {
    const ref = ContentRef.fromString(key);
    switch (ref.contentType) {
      case 'document':
        documents[ref.contentId] = content;
        break;
      case 'form':
        forms[ref.contentId] = content;
        break;
      case 'weblink':
        weblinks[ref.contentId] = content;
        break;
      case 'message':
        messages[ref.contentId] = content;
        break;
    }
  }
  return {
    type: 'core/load-content-success' as const,
    payload: {
      byRef: contentMap,
      documents,
      forms,
      weblinks,
      messages,
    },
  };
};
