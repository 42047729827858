import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'antd';
import cn from 'classnames';

import Error from './Error';
import PermissionsError from './PermissionsError';

const DashboardCard = React.forwardRef(
  ({ className, children, noPadding, title, onClose, ...props }, ref) => {
    const finalClass = cn('dashboard-card', className, {
      'no-padding': !props.loading && noPadding,
      'closeable-card': !!onClose,
    });
    return (
      <PermissionsError>
        <Card {...props} className={finalClass} ref={ref} title={title ? <h3>{title}</h3> : null}>
          <Error>
            {onClose ? (
              <Button className="dashboard-card-close-button" icon="close" onClick={onClose} />
            ) : null}
            {children}
          </Error>
        </Card>
      </PermissionsError>
    );
  },
);

DashboardCard.propTypes = {
  className: PropTypes.string,
  noPadding: PropTypes.bool,
};

DashboardCard.defaultProps = {
  className: '',
  noPadding: false,
};

DashboardCard.Single = DashboardCard;
DashboardCard.Double = DashboardCard;
DashboardCard.Half = DashboardCard;
DashboardCard.Full = DashboardCard;

export default DashboardCard;
