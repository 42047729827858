import { put, call, select, spawn, take } from 'redux-saga/effects';
import { message } from 'antd';
import i18n from '../../../i18n';
import doCreateUISAdminClient from '../../doCreateUISAdminClient';
import {
  appUserActivated,
  appUserActivationFailed,
  appUserDeactivated,
  appUserDeactivationFailed,
  resendAppUserInviteFailed,
  resendAppUserInviteSuccess,
  IActivateAppUser,
  IDeactivateAppUser,
  IResendAppUserInvite,
} from '../actions';
import { selectAppUser } from '../reducers';
import { buildProfileFromAppUser, createRegistrationCode } from '../utils';
import { channel } from 'redux-saga';
import { fetchHospitalNameAsync } from './manage';

export function* doActivateAppUser({ payload: { appUserId } }: IActivateAppUser): any {
  try {
    const uisClient = yield doCreateUISAdminClient();
    const [, appUser] = yield select(selectAppUser(appUserId));

    const appUserProfile = buildProfileFromAppUser(appUser);

    yield call(uisClient.updateAppUser, appUserId, appUserProfile, false);

    yield put(appUserActivated(appUserId));
    yield call(message.success, i18n.t('patients:IndividualDetail.appAccess.activationSuccess'));
  } catch (err) {
    console.error(err);
    yield put(appUserActivationFailed(appUserId));
    yield call(message.error, i18n.t('patients:IndividualDetail.appAccess.activationFailed'));
  }
}

export function* doDeactivateAppUser({ payload: { appUserId } }: IDeactivateAppUser): any {
  try {
    const uisClient = yield doCreateUISAdminClient();
    const [, appUser] = yield select(selectAppUser(appUserId));

    const appUserProfile = buildProfileFromAppUser(appUser);

    yield call(uisClient.updateAppUser, appUserId, appUserProfile, true);

    yield put(appUserDeactivated(appUserId));
    yield call(message.success, i18n.t('patients:IndividualDetail.appAccess.deactivationSuccess'));
  } catch (err) {
    console.error(err);
    yield put(appUserDeactivationFailed(appUserId));
    yield call(message.error, i18n.t('patients:IndividualDetail.appAccess.deactivationFailed'));
  }
}

export function* doResendAppUserInvite({ payload: { appUserId } }: IResendAppUserInvite): any {
  try {
    // Go fetch the hospital name we'll need for the invite later, without blocking.
    const hospitalNameChannel = channel<string>();
    yield spawn(fetchHospitalNameAsync, hospitalNameChannel);

    const uisClient = yield doCreateUISAdminClient();
    const [, appUser] = yield select(selectAppUser(appUserId));
    const hospitalName = yield take(hospitalNameChannel);

    yield call(uisClient.createUserRegistrationCode, appUser.url, createRegistrationCode(), {
      hospitalName,
    });
    yield put(resendAppUserInviteSuccess());
    yield call(message.success, i18n.t('patients:IndividualDetail.appAccess.codeSent'));
  } catch (err) {
    console.error(err);
    yield put(resendAppUserInviteFailed());
    yield call(message.error, i18n.t('patients:IndividualDetail.appAccess.codeSendFailed'));
  }
}
