import { ruleWhatToContentRefType } from '@constants';
import { PEPContentTypes } from './contentTypes';

class ContentRef {
  constructor(
    public organisationId: string,
    public contentType: string,
    public contentId: string,
    public contentVersion: string | undefined = undefined,
  ) {}

  public toString() {
    let ref = `${this.organisationId}/${this.contentType.toLowerCase()}/${this.contentId}/`;
    // This is disabled until the use of version numbers and the Managed Content Update feature are implemented.
    // if (this.contentVersion) {
    //   ref += `${this.contentVersion}`;
    // }
    return ref;
  }

  public toUrlFragment() {
    return this.toString().replace(new RegExp('/', 'g'), '::');
  }

  public toDashboardUrl(pepContentType: PEPContentTypes | undefined = undefined) {
    let contentType = pepContentType
      ? pepContentType.toLowerCase()
      : this.contentType.toLowerCase();
    if (contentType === 'website' || contentType === 'web_form') {
      contentType = 'weblink';
    }
    return `/content/${contentType}s/${this.toUrlFragment()}`;
  }

  public matches(otherContentRef: string | ContentRef, options?: { ignoreVersion: boolean }) {
    if (typeof otherContentRef === 'string') {
      otherContentRef = ContentRef.fromString(otherContentRef);
    }
    return (
      this.organisationId === otherContentRef.organisationId &&
      this.contentType === otherContentRef.contentType &&
      this.contentId === otherContentRef.contentId &&
      (options?.ignoreVersion ? true : this.contentVersion === otherContentRef.contentVersion)
    );
  }

  public static fromString(stringRef: string) {
    const parts = stringRef.split('/');
    const ref = new ContentRef(parts[0], parts[1], parts[2], parts[3]?.toString());
    return ref;
  }

  public static fromUrlFragment(fragment: string) {
    const asStr = fragment.replace(new RegExp('::', 'g'), '/');
    return ContentRef.fromString(asStr);
  }

  public static guessContentId(obj: any): string | undefined {
    return obj.content_id || obj.uuid || obj.product_id || obj.id;
  }

  public static fromRuleAndOrgId = (
    rule: { what: string; whatDetail: { [key: string]: any } },
    organisationId: string,
  ): ContentRef => {
    const contentType = ruleWhatToContentRefType(rule.what);
    const contentId = ContentRef.guessContentId(rule.whatDetail);
    // get version number from the latest version for Content
    let contentVersion: number | undefined =
      rule.whatDetail.latestVersion && rule.whatDetail.latestVersion.number;
    if (!contentVersion) {
      // Documents have their propert in snake-case... so cater to that if there is no version number value yet.
      contentVersion = rule.whatDetail.latest_version && rule.whatDetail.latest_version.number;
    }
    const ref = new ContentRef(organisationId, contentType, contentId!, contentVersion?.toString());
    return ref;
  };
}

export default ContentRef;
