import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { fetchAppUsersPathways } from '@pathways/redux/appUserPathways/actions';
import { selectAppUsers } from '@redux/appUsers/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import createColumns from './createColumns';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import SearchableTable from '@components/SearchableTable';
import { selectPathwaysForAllUsers } from '@pathways/redux/appUserPathways/reducers';
import { selectAllPathwaysById } from '@pathways/redux/pathways/reducers';
import useFilters from '@hooks/useFilters';
import moment from 'moment';
import './styles.less';

const filterFunc = (value, comparator) => {
  const blacklist = ['hospitalId', 'hospitalSlug', 'id', 'language', 'url', 'userUrl'];
  const dateOfBirth = moment(value.dateOfBirth).format('DD/MM/YYYY');
  let result =
    comparator(`${value.firstName} ${value.lastName}`) ||
    comparator(value.allPathwayNamesAsString) ||
    comparator(value.dateOfBirth) ||
    comparator(dateOfBirth);

  Object.entries(value).forEach(([key, value]) => {
    if (!blacklist.includes(key) && typeof value === 'string') {
      result = result || comparator(value);
    }
  });

  return result;
};

const AppUserListCard = ({ showTitle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentFilters] = useFilters();

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchPathways());
    dispatch(fetchAppUsersPathways());
  }, [dispatch]);

  const [loading, appUsers] = useSelector(selectAppUsers);

  /*
    The below generates:
    {
      ...appUsers,
      allPathwayNamesAsString: "{name of all the user's pathways} || ''"
      allPathwayNames: [{name of all the user's pathways}]
    }
  */
  const [pathwaysForAllUsersLoading, pathwaysForAllUsers] = useSelector(selectPathwaysForAllUsers);
  const [allPathwaysByIdLoading, allPathwaysById] = useSelector(selectAllPathwaysById);
  const appUsersWithPathwayNames = appUsers.map(appUser => {
    let appUserWithPathwayNames = {};
    pathwaysForAllUsers.forEach(pathwayForAllUsers => {
      const allAppUserPathways = pathwayForAllUsers[appUser.ids.pathways] ?? [];
      const allOriginalPathways = allAppUserPathways.map(({ originalPathway }) => originalPathway);
      const allPathwayNames = allOriginalPathways.map(
        originalPathway => allPathwaysById[originalPathway]?.name || '',
      );
      const allPathwayNamesAsString = allPathwayNames.join(' ');
      appUserWithPathwayNames = {
        ...appUser,
        allPathwayNamesAsString,
        allPathwayNames,
      };
    });
    return appUserWithPathwayNames;
  });

  const { [Permissions.ViewPatients]: canViewPII } = useHasPermissions(Permissions.ViewPatients);
  const columns = useMemo(
    () => createColumns(t, appUsersWithPathwayNames, canViewPII, currentFilters),
    [t, appUsersWithPathwayNames, canViewPII, currentFilters],
  );

  return (
    <SearchableTable
      className="app-user-list-card"
      columns={columns}
      dataSource={appUsersWithPathwayNames}
      filterFunc={filterFunc}
      hasRoutedPagination
      loading={pathwaysForAllUsersLoading || allPathwaysByIdLoading || loading}
      title={showTitle ? <h3>{t('cards:DocumentList.title')}</h3> : null}
      onRowClick={({ id }) => history.push(`/patients/individuals/${id}/overview`)}
    />
  );
};

export default AppUserListCard;
