import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty, Form } from 'antd';
import DetailRow from '@components/DetailRow';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import LanguageIcon from '@components/LanguageIcon';
import { Card } from '../../Card';
import { ColumnGroup, Column } from '@cards/Card';
import moment from 'moment';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import AppUserNameOrEmail from '../AppUserNameOrEmail';
import './styles.less';

const formItemLayout = {
  layout: 'vertical',
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const AppUserDetailsCard = ({ appUser, title, loading, ...props }) => {
  const { t } = useTranslation();
  const { [Permissions.ViewPatients]: canViewPII } = useHasPermissions(Permissions.ViewPatients);
  const obfuscatePIValue = '******';

  return (
    <Card className="app-user-details-card" noPadding loading={loading} title={t(title)} {...props}>
      {appUser === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form {...formItemLayout} className="detail-form">
          <ColumnGroup>
            <Column>
              <DetailRow
                label={t('cards:PatientDetails.labels.fullName')}
                value={
                  canViewPII
                    ? `${appUser.firstName || ''} ${appUser.lastName || ''}`
                    : obfuscatePIValue
                }
              />
              <DetailRow
                label={t('cards:PatientDetails.labels.mobile')}
                value={canViewPII ? appUser.phone : obfuscatePIValue}
              />

              <DetailRow
                className="email"
                label={t('cards:PatientDetails.labels.email')}
                value={<AppUserNameOrEmail appUser={appUser} forceEmailValue={true} />}
              />

              <DetailRow
                label={t('cards:PatientDetails.labels.address')}
                value={
                  canViewPII
                    ? `${appUser.address ? `${appUser.address}, ` : ''}${
                        appUser.city ? `${appUser.city} ` : ''
                      }${appUser.postcode || ''}`
                    : obfuscatePIValue
                }
              />
              {/* <DetailRow
            label={t('cards:PatientDetails.labels.age')}
            value={appUser.profile.age}
            /> */}

              <DetailRow
                label={t('cards:PatientDetails.labels.dateOfBirth')}
                value={
                  canViewPII
                    ? appUser.dateOfBirth
                      ? moment(appUser.dateOfBirth).format('L')
                      : ''
                    : obfuscatePIValue
                }
              />
            </Column>
            <Column>
              <DetailRow
                label={t('cards:PatientDetails.labels.sex')}
                value={
                  appUser.sex && canViewPII
                    ? t(`cards:PatientDetails.labels.gender.${appUser.sex}`)
                    : obfuscatePIValue
                }
              />

              <DetailRow
                label={t('cards:PatientDetails.labels.title')}
                value={canViewPII ? appUser.title : obfuscatePIValue}
              />

              <DetailRow label={t('cards:PatientDetails.labels.userId')} value={appUser.id} />

              <DetailRow
                label={t('cards:PatientDetails.labels.language')}
                value={<LanguageIcon showText language={appUser.language} />}
              />
            </Column>
          </ColumnGroup>
        </Form>
      )}
    </Card>
  );
};

AppUserDetailsCard.propTypes = {
  title: string,
  appUser: object,
};

AppUserDetailsCard.defaultProps = {
  title: 'cards:PatientDetails.title',
};

const AppUserDetailByIdCard = ({ appUserId, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  const [loading, appUser] = useSelector(selectAppUser(appUserId));

  return <AppUserDetailsCard appUser={appUser} loading={loading} {...props} />;
};

export { AppUserDetailsCard };

export default AppUserDetailByIdCard;
