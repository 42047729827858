import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Tabs } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { selectPathwayWithRules } from '@pathways/redux/pathways/reducers';
import { selectIndexEvents } from '@pathways/redux/indexEvents/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { fetchRules } from '@pathways/redux/rules/actions';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import { extractSlugFromTypeSlug } from '@pathways/redux/indexEvents/utils';
import { Card } from '../../../../cards/Card';
import RuleTable from './RuleTable';
import './style.less';

const { TabPane } = Tabs;

function IndexEventTriggeredRules({ pathwayId, journey }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { i18n, t } = useTranslation();
  const [loading, pathway] = useSelector(selectPathwayWithRules(pathwayId));
  const [ieLoading, indexEventTypes] = useSelector(selectIndexEvents);

  const todaysDate = useMemo(() => moment().format('YYYY-MM-DD'), []);

  useEffect(() => {
    dispatch(fetchPathways());
    dispatch(fetchRules());
    dispatch(fetchIndexEvents());
  }, [dispatch]);

  const orderedIndexEvents = useMemo(
    () =>
      (journey.indexEvents || []).sort((a, b) => {
        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;
        return 0;
      }),
    [journey],
  );

  const triggeredRuleIds = useMemo(
    () =>
      (journey?.entries || []).reduce(
        (ruleIds, entry) =>
          entry.type === 'rule_execution' ? [...ruleIds, entry.data.original_rule_id] : ruleIds,
        [],
      ),
    [journey],
  );

  const indexEventsWithoutDates = useMemo(() => {
    if (pathway && journey?.indexEvents) {
      return pathway.indexEvents.reduce((prev, indexEvent) => {
        if (
          !journey.indexEvents.some(
            ({ eventTypeSlug: slug }) => slug === extractSlugFromTypeSlug(indexEvent.eventTypeSlug),
          )
        ) {
          const indexEventType = indexEventTypes.find(
            ({ slug }) => slug === extractSlugFromTypeSlug(indexEvent.eventTypeSlug),
          );

          return [...prev, { ...indexEventType, ...indexEvent }];
        }

        return prev;
      }, []);
    }

    return [];
  }, [indexEventTypes, journey, pathway]);

  const languageCode = useMemo(() => i18n.language.replace(/-.*/, ''), [i18n]);

  const rulesByDate = useMemo(() => {
    if (!pathway || !journey.entries) return {};

    const timelineData = pathway.indexEvents.reduce(
      (prev, { eventTypeSlug, rules }) => ({
        ...prev,
        [eventTypeSlug]: [...rules].sort((a, b) => b.whenDetail.days - a.whenDetail.days),
      }),
      {},
    );

    const triggeredRuleIdsForPathway = journey.entries.reduce(
      (ids, entry) =>
        entry.data.original_pathway_id === pathway.id ? [...ids, entry.data.original_rule_id] : ids,
      [],
    );

    return Object.keys(timelineData).reduce(
      (prev, indexEventSlug) => {
        const indexEvent = orderedIndexEvents.find(
          ({ eventTypeSlug }) => eventTypeSlug === indexEventSlug,
        );
        const indexEventType = indexEventTypes.find(({ slug }) => slug === indexEventSlug);

        if (!indexEvent || !indexEventType) return prev;

        const tempRulesByDate = { ...prev };
        const indexEventMoment = moment(indexEvent.value);
        timelineData[indexEventSlug].forEach(({ whenDetail, ...ruleData }) => {
          const whenDate = whenDetail.days
            ? moment(indexEventMoment)
                .add(Number(whenDetail.days), 'days')
                .format('YYYY-MM-DD')
            : moment(indexEventMoment).format('YYYY-MM-DD');

          tempRulesByDate[whenDate] = [
            ...(tempRulesByDate[whenDate] || []),
            {
              ...ruleData,
              whenDetail,
              whenDate,
              isTriggered: triggeredRuleIdsForPathway.includes(ruleData.id),
              indexEventName: indexEventType.translatedNames[i18n.language] || indexEventType.name,
            },
          ];
        });
        return tempRulesByDate;
      },
      { [todaysDate]: [] },
    );
  }, [journey, pathway, indexEventTypes, i18n, orderedIndexEvents, todaysDate]);

  const indexEventsByDate = useMemo(
    () =>
      orderedIndexEvents.reduce((prev, curr) => {
        const indexEventDate = moment(curr.value).format('YYYY-MM-DD');
        const indexEventType = indexEventTypes.find(({ slug }) => curr.eventTypeSlug === slug);
        const indexEventDetails = { ...curr, ...indexEventType };

        return {
          ...prev,
          [indexEventDate]: [...(prev[indexEventDate] || []), indexEventDetails],
        };
      }, {}),
    [indexEventTypes, orderedIndexEvents],
  );

  const indexEventsWithoutDatesByTiming = useMemo(() => {
    return indexEventsWithoutDates.reduce((prev, indexEvent) => {
      return {
        ...prev,
        [indexEvent.slug]: indexEvent.rules.reduce(
          (rules, rule) => {
            if (rule.whenDetail.days) {
              return {
                ...rules,
                [rule.whenDetail.days]: [...(rules[rule.whenDetail.days] || []), rule],
              };
            }

            return { ...rules, '0': [...rules['0'], rule] };
          },
          { '0': [] },
        ),
      };
    }, {});
  }, [indexEventsWithoutDates]);

  if (loading || ieLoading) {
    return <Card.Half loading title={t('cards:ProcedurePathwayIndexEvents.title')} />;
  }

  if (!pathway) return null;

  return (
    <Card.Half
      className="app-user-index-event-triggered-rules-card"
      extra={
        <Button
          onClick={() => history.push(`/patients/individuals/${match.params.id}/journey/edit`)}
        >
          {t('cards:AppUserIndexEventTriggeredRules.edit')}
        </Button>
      }
      title={t('cards:ProcedurePathwayIndexEvents.title')}
    >
      {indexEventsWithoutDates.length ? (
        <Tabs>
          {Object.keys(indexEventsByDate).length ? (
            <TabPane tab="Defined Dates" key="defined">
              <RuleTable
                indexEventsByDate={indexEventsByDate}
                rulesByDate={rulesByDate}
                todaysDate={todaysDate}
                triggeredRules={triggeredRuleIds}
              />
            </TabPane>
          ) : null}
          {indexEventsWithoutDates.map(indexEvent => (
            <TabPane
              tab={indexEvent.translatedNames[languageCode] || indexEvent.name}
              key={indexEvent.slug}
            >
              <RuleTable
                indexEventsByDate={{ '0': [indexEvent] }}
                rulesByDate={indexEventsWithoutDatesByTiming[indexEvent.slug]}
              />
            </TabPane>
          ))}
        </Tabs>
      ) : (
        <RuleTable
          indexEventsByDate={indexEventsByDate}
          rulesByDate={rulesByDate}
          todaysDate={todaysDate}
          triggeredRules={triggeredRuleIds}
        />
      )}
    </Card.Half>
  );
}

export default IndexEventTriggeredRules;
