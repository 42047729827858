import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_COMPLETE,
  CHANGE_PASSWORD_ERROR,
  PROFILE_REFRESH_TERMS_OF_USE,
} from './types';
import { IReduxAction } from '../types';

export type IChangePassword = IReduxAction<
  typeof CHANGE_PASSWORD,
  { oldPassword: string; newPassword: string }
>;
export const changeCurrentPassword = (
  oldPassword: string,
  newPassword: string,
): IChangePassword => ({
  type: CHANGE_PASSWORD,
  payload: { oldPassword, newPassword },
});

export type IChangePasswordComplete = IReduxAction<typeof CHANGE_PASSWORD_COMPLETE>;
export const changePasswordComplete = (): IChangePasswordComplete => ({
  type: CHANGE_PASSWORD_COMPLETE,
  payload: {},
});

export type IChangePasswordError = IReduxAction<typeof CHANGE_PASSWORD_ERROR>;
export const changePasswordError = (): IChangePasswordError => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: {},
});

export type IProfileRefreshTermsOfUse = IReduxAction<typeof PROFILE_REFRESH_TERMS_OF_USE>;
export const profileRefreshTermsOfUse = (): IProfileRefreshTermsOfUse => ({
  type: PROFILE_REFRESH_TERMS_OF_USE,
  payload: {},
});
