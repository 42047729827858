import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PathwayDetails from './PathwayDetails';
import JourneyDetails from './JourneyDetails';
import EditJourney from './EditJourney';
import './style.less';

function AppUserPathways() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/pathway/:pathwayId`} component={PathwayDetails} />
      <Route path={`${match.path}/edit`} component={EditJourney} />
      <Route path={match.path} component={JourneyDetails} />
    </Switch>
  );
}

export default AppUserPathways;
