import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Column } from '@cards/Card';
import { selectAppUser } from '@redux/appUsers/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectJourneysForAppUser } from '@pathways/redux/appUserPathways/reducers';
import {
  fetchAppUsersPathways,
  fetchAppUserJourneys,
} from '@pathways/redux/appUserPathways/actions';
import JourneyDetailsCard from '../../cards/Journeys/Details';
import JourneyTimelineCard from '../../cards/Journeys/Timeline';
import { setCurrentScreen, screens } from '../../../analytics';

function JourneyDetails() {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const {
    params: { id: appUserId },
  } = match;

  useEffect(() => {
    setCurrentScreen(screens.APP_USER_JOURNEY);
  }, []);

  useEffect(() => {
    dispatch(fetchPathways());
    dispatch(fetchAppUserJourneys(appUserId));
    dispatch(fetchAppUsersPathways());
  }, [appUserId, dispatch]);

  const [, appUser] = useSelector(selectAppUser(appUserId));
  const [, journeys] = useSelector(selectJourneysForAppUser(appUser));

  // only supporting 1 journey for now
  const journeyId = journeys?.[0]?.id;

  return (
    <Column>
      <JourneyDetailsCard appUserId={appUserId} journeyId={journeyId} />
      <JourneyTimelineCard appUserId={appUserId} journeyId={journeyId} />
    </Column>
  );
}

export default JourneyDetails;
