import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import { selectAppUserMainPathway } from '@pathways/redux/pathways/reducers';
import { selectPathwaysForAppUser } from '@pathways/redux/appUserPathways/reducers';
import { appUserType } from '../../../propTypes';
import './styles.less';

function PathwayProgress({ appUser }) {
  const { t } = useTranslation();
  const [, pathways] = useSelector(selectPathwaysForAppUser(appUser));
  const [, pathway] = useSelector(
    selectAppUserMainPathway(
      pathways && pathways.length ? pathways.map(({ originalPathway }) => originalPathway) : [],
    ),
  );

  const currentStageSlug = useMemo(() => {
    if (pathway) {
      const appUserPathway = pathways.find(({ originalPathway }) => originalPathway === pathway.id);
      return appUserPathway?.currentStageSlug;
    }

    return undefined;
  }, [pathways, pathway]);

  const [progressPercent, currentStage] = useMemo(() => {
    if (pathway && currentStageSlug) {
      const currentStage = pathway.stages.find(stage => stage.slug === currentStageSlug);
      if (currentStage) {
        return [
          parseInt(((currentStage.number / pathway.stages.length) * 100).toFixed(0)),
          currentStage,
        ];
      }
    }

    return [0];
  }, [pathway, currentStageSlug]);

  return (
    <>
      <Progress percent={progressPercent} showInfo={false} />
      <div>
        {currentStage ? currentStage.name : t('cards:PatientList.pathwayProgress.noCurrentStage')}
      </div>
    </>
  );
}

PathwayProgress.propTypes = {
  appUser: appUserType,
};

export default PathwayProgress;
