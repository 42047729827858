import { CHANGE_PASSWORD, CHANGE_PASSWORD_COMPLETE, CHANGE_PASSWORD_ERROR } from './types';
import { IChangePassword, IChangePasswordComplete, IChangePasswordError } from './actions';
import { combineReducers } from 'redux';

const loading = (
  state: boolean = false,
  action: IChangePassword | IChangePasswordComplete | IChangePasswordError,
): boolean => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return true;
    case CHANGE_PASSWORD_COMPLETE:
    case CHANGE_PASSWORD_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  loading,
});

export interface IMyProfileState {
  myProfile: {
    loading: boolean;
  };
}

export const isLoading = (state: IMyProfileState): boolean => state.myProfile.loading;
