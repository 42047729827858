import React from 'react';
import { createUniqueArray } from '@utils';
import PathwayProgress from './PathwayProgress';
import PathwayName from './PathwayName';
import moment from 'moment';
import AppUserNameOrEmail from '../AppUserNameOrEmail';
import './styles.less';

export default (t, appUsers, canViewPII, currentFilters) => {
  return [
    {
      title: t('cards:PatientList.columns.name'),
      key: 'name',
      render: appUser => (
        <AppUserNameOrEmail appUser={appUser} displayInline={true} linkEmailValue={false} />
      ),
      sorter: (a, b) =>
        `${a.firstName} ${b.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
      onFilter: (value, record) => `${record.firstName} ${record.lastName}` === value,
    },
    {
      title: t('cards:PatientList.columns.dateOfBirth'),
      key: 'dateOfBirth',
      render: appUser => (appUser.dateOfBirth ? moment(appUser.dateOfBirth).format('L') : ''),
      sorter: (a, b) => {
        const aValue = a.dateOfBirth ? a.dateOfBirth : '1900-01-01';
        const bValue = b.dateOfBirth ? b.dateOfBirth : '1900-01-01';
        return Date.parse(aValue) - Date.parse(bValue);
      },
      width: 190,
    },
    {
      title: t('cards:PatientList.columns.doctor'),
      key: 'doctor',
      render: appUser => appUser.doctor,
      sorter: (a, b) => a.doctor.localeCompare(b.doctor),
      filteredValue: currentFilters.doctor ?? [],
      filters: createUniqueArray(appUsers.map(appUser => appUser.doctor))
        .sort((a, b) => a.localeCompare(b))
        .map(doctor => ({
          text: doctor,
          value: doctor,
        })),
      onFilter: (value, record) => record.doctor === value,
    },
    {
      title: t('cards:PatientList.columns.pathwayName'),
      key: 'pathwayName',
      render: appUser => <PathwayName appUser={appUser} />, // TODO fix how this column retrieves appUser pathway information
      filteredValue: currentFilters.pathwayName ?? [],
      filters: createUniqueArray(appUsers.flatMap(appUser => appUser.allPathwayNames))
        .sort((a, b) => a.localeCompare(b))
        .map(pathwayName => ({
          text: pathwayName,
          value: pathwayName,
        })),
      onFilter: (value, record) => record.allPathwayNames.includes(value),
    },
    {
      title: t('cards:PatientList.columns.pathwayProgress'),
      key: 'pathwayProgress',
      render: appUser => {
        return <PathwayProgress appUser={appUser} />;
      },
      fixed: 'right',
      width: 300,
    },
  ];
};
