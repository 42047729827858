import { EngagementEvent, DailyStep } from '@liquid-state/uis-client/dist/types';
export const ACTIVATE_APP_USER = 'ACTIVATE_APP_USER';
export const APP_ACCESS_CODE_GENERATION_FAILED = 'APP_ACCESS_CODE_GENERATION_FAILED';
export const APP_USER_ACTIVATED = 'APP_USER_ACTIVATED';
export const APP_USER_ACTIVATION_FAILED = 'APP_USER_ACTIVATION_FAILED';
export const APP_USER_DEACTIVATED = 'APP_USER_DEACTIVATED';
export const APP_USER_DEACTIVATION_FAILED = 'APP_USER_DEACTIVATION_FAILED';
export const APP_USERS_LOADED = 'APP_USERS_LOADED';
export const APP_USERS_FORMS_LOADED = 'APP_USERS_FORMS_LOADED';

export const UPDATE_APP_USER = 'UPDATE_APP_USER';
export const UPDATE_APP_USER_SUCCESS = 'UPDATE_APP_USER_SUCCESS';
export const UPDATE_APP_USER_FAILED = 'UPDATE_APP_USER_FAILED';

export const APP_USER_SUBMIT_FORM = 'APP_USER_SUBMIT_FORM';
export const APP_USER_SUBMIT_FORM_SUCCESS = 'APP_USER_SUBMIT_FORM_SUCCESS';
export const APP_USER_SUBMIT_FORM_FAILED = 'APP_USER_SUBMIT_FORM_FAILED';
export const APP_USER_FORM_COMPUTATIONS_LOADED = 'APP_USER_FORM_COMPUTATIONS_LOADED';

export const DEACTIVATE_APP_USER = 'DEACTIVATE_APP_USER';
export const DELETE_APP_USERS = 'DELETE_APP_USERS';
export const FETCH_APP_USERS = 'FETCH_APP_USERS';
export const REFRESH_APP_USERS = 'REFRESH_APP_USERS';
export const FETCH_APP_USERS_FAILED = 'FETCH_APP_USERS_FAILED';
export const FETCH_APP_USERS_FORMS = 'FETCH_APP_USERS_FORMS';
export const LOAD_APP_USERS_FORMS = 'LOAD_APP_USERS_FORMS';
export const INVITE_APP_USER = 'INVITE_APP_USER';
export const INVITE_APP_USER_SUCCESS = 'INVITE_APP_USER_SUCCESS';
export const INVITE_APP_USER_FAILED = 'INVITE_APP_USER_FAILED';
export const LOAD_APP_USERS = 'LOAD_APP_USERS';
export const RESEND_APP_USER_INVITE = 'RESEND_APP_USER_INVITE';
export const RESEND_APP_USER_INVITE_FAILED = 'RESEND_APP_USER_INVITE_FAILED';
export const RESEND_APP_USER_INVITE_SUCCESS = 'RESEND_APP_USER_INVITE_SUCCESS';

export const FETCH_APP_USER_FILES = 'FETCH_APP_USER_FILES';
export const FETCH_APP_USER_FILES_SUCCESS = 'FETCH_APP_USER_FILES_SUCCESS';
export const FETCH_APP_USER_FILES_FAILED = 'FETCH_APP_USER_FILES_FAILED';
export const CREATE_APP_USER_FILE = 'CREATE_APP_USER_FILE';
export const CREATE_APP_USER_FILE_SUCCESS = 'CREATE_APP_USER_FILE_SUCCESS';
export const CREATE_APP_USER_FILE_FAILED = 'CREATE_APP_USER_FILE_FAILED';
export const UPDATE_APP_USER_FILE = 'UPDATE_APP_USER_FILE';
export const UPDATE_APP_USER_FILE_SUCCESS = 'UPDATE_APP_USER_FILE_SUCCESS';
export const UPDATE_APP_USER_FILE_FAILED = 'UPDATE_APP_USER_FILE_FAILED';
export const REMOVE_APP_USER_FILE = 'REMOVE_APP_USER_FILES';
export const REMOVE_APP_USER_FILE_SUCCESS = 'REMOVE_APP_USER_FILES_SUCCESS';
export const REMOVE_APP_USER_FILE_FAILED = 'REMOVE_APP_USER_FILES_FAILED';
export const GET_APP_USER_FILE_DOWNLOAD_URL = 'GET_APP_USER_FILE_DOWNLOAD_URL';
export const GET_APP_USER_FILE_DOWNLOAD_URL_SUCCESS = 'GET_APP_USER_FILE_DOWNLOAD_URL_SUCCESS';
export const GET_APP_USER_FILE_DOWNLOAD_URL_FAILED = 'GET_APP_USER_FILE_DOWNLOAD_URL_FAILED';
export const CLEAR_APP_USER_FILE_DOWNLOAD_URL = 'CLEAR_APP_USER_FILE_DOWNLOAD_URL';

export const FETCH_APP_USER_ENGAGEMENTS = 'FETCH_APP_USER_ENGAGEMENTS';
export const FETCH_APP_USER_ENGAGEMENTS_SUCCESS = 'FETCH_APP_USER_ENGAGEMENTS_SUCCESS';
export const FETCH_APP_USER_ENGAGEMENTS_FAILED = 'FETCH_APP_USER_ENGAGEMENTS_FAILED';

export const FETCH_APP_USER_DAILY_STEPS = 'FETCH_APP_USER_DAILY_STEPS';
export const FETCH_APP_USER_DAILY_STEPS_SUCCESS = 'FETCH_APP_USER_DAILY_STEPS_SUCCESS';
export const FETCH_APP_USER_DAILY_STEPS_FAILED = 'FETCH_APP_USER_DAILY_STEPS_FAILED';

export const FETCH_APP_USER_EXTERNAL_FORM_SUBMISSIONS_FORMS =
  'FETCH_APP_USER_EXTERNAL_FORM_SUBMISSIONS_FORMS';

export const ADD_APP_USER_VIEWED_CONTENT_ID = 'ADD_APP_USER_VIEWED_CONTENT_ID';

export interface IAppUser {
  address: string;
  city: string;
  dateOfBirth: string;
  doctor: string;
  email: string;
  firstName: string;
  hospitalId: string;
  id: string;
  ids: {
    [key: string]: string;
  };
  invitationCodes: IUISInvitationCode[];
  isActive: boolean;
  language: string;
  lastName: string;
  phone: string;
  postcode: string;
  sex: string;
  title: string;
  emailVerified: boolean;
  phoneVerified: boolean;
  url: string;
  userUrl: string | null;
  uuid: string;
  _pep: any; // profile data to pwer certain PEP Health features such as "My Team"
  files?: {
    files?: any[];
    loading?: boolean;
  };
  downloadUrl?: {
    url?: string;
    loading?: boolean;
  };
  engagements?: {
    loading: boolean;
    engagements: EngagementEvent[];
  };
  healthData?: {
    dailySteps: {
      loading: boolean;
      dailySteps: DailyStep[];
    };
  };
  created: string;
  viewedContentIds?: string[];
}

interface IPipIds {
  pip: string;
  uuid: string;
}

interface IUISInvitationCode {
  code: string;
  consumed: boolean;
  expires_at: string;
}

export type IUISUserProfile = {
  address: string;
  age?: number;
  city: string;
  dateOfBirth: string;
  doctor: string;
  email: string;
  firstName: string;
  hospital?: string;
  hospitalSlug?: string;
  hospitalId: string;
  language: string;
  lastName: string;
  phone: string;
  postcode: string;
  sex: string;
  title: string;
  yearOfBirth?: number;
  _pep: any; // profile data to pwer certain PEP Health features such as "My Team"
  files: any;
};

export interface IUISUser {
  app_id: number;
  ids: IPipIds[];
  disabled: boolean;
  codes: IUISInvitationCode[];
  profile: IUISUserProfile;
  email_verified: boolean;
  phone_verified: boolean;
  url: string;
  user: string | null;
  uuid: string;
  created: string;
}

export interface IFormSubmissionRaw {
  formId: string;
  versions: {
    [key: string]: number;
  };
  created: string;
  uuid: string;
  organisationId?: string;
  distributorId?: string;
}

export interface IFormSubmission {
  formId: string;
  versions: {
    [key: string]: number;
  };
  created: string;
  uuid: string;
  organisationId?: string;
}

interface IFormComputationResult {
  [key: string]: string | number;
  _created: string;
}

export interface IRawFormComputation {
  data_object_uuid: string;
  data_object_version: number;
  latest: IFormComputationResult;
  previous: IFormComputationResult;
}

export interface IFormComputation {
  dataObjectUUID: string;
  dataObjectVersion: number;
  latest: IFormComputationResult;
  previous: IFormComputationResult;
}

export interface IAppUsersDataState {
  byId: { [key: string]: IAppUser };
  list: string[];
}

export interface IAppUsersFormsState {
  computations: {
    [key: string]: IFormComputation[];
  };
  data: {
    [key: string]: IFormSubmission[];
  };
  loading: boolean;
  submitting: boolean;
}
