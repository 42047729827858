import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card, Button, Input, Form, Icon, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import OnboardingContainer from '@components/OnboardingContainer';
import { isLoading, hasError as error } from '@redux/login/reducer';
import { authenticationSubmitted } from '@redux/login/actions';

import './style.less';

const LoginAuthentication = ({
  form: { getFieldDecorator, validateFieldsAndScroll, getFieldError, getFieldValue, resetFields },
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isStarted = useSelector(state => state.login.started);
  const loading = useSelector(isLoading);
  const hasError = useSelector(error);
  const phoneNumber = useSelector(state => state.login.authentication.phoneNumber);

  if (!isStarted) {
    return <Redirect to="/auth" />;
  }

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        dispatch(authenticationSubmitted(values));
        resetFields(['code']);
      }
    });
  };

  const disabledSubmit = typeof getFieldError('code') !== 'undefined' || !getFieldValue('code');

  return (
    <OnboardingContainer>
      <Card
        title={<h1>{t('login:title')}</h1>}
        actions={[
          <Button
            type="primary"
            onClick={handleSubmit}
            htmlType="submit"
            disabled={disabledSubmit}
            loading={loading}
          >
            {t('common:buttons.login')}
          </Button>,
        ]}
        className="login-card"
      >
        <p>{t('login:LoginAuthentication.description', { hidden: phoneNumber })}</p>
        {hasError && (
          <Alert
            message={t('login:LoginAuthentication.alert.message')}
            description={t('login:LoginAuthentication.alert.description')}
            type="error"
            showIcon
          />
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Item hasFeedback>
            {getFieldDecorator('code', {
              rules: [
                {
                  required: true,
                  message: t('common:validation.Code.emptyVerificationCode'),
                },
                {
                  len: 6,
                  pattern: /^[0-9]*$/,
                  message: t('common:validation.Code.invalidVerificationCode'),
                },
              ],
            })(
              <Input
                maxLength={6}
                inputMode="numeric"
                prefix={<Icon type="lock" />}
                placeholder={t('common:placeholder.code')}
              />,
            )}
          </Form.Item>
        </Form>
      </Card>
    </OnboardingContainer>
  );
};

LoginAuthentication.propTypes = {
  form: PropTypes.object.isRequired,
};

const WrappedLoginAuthentication = Form.create()(LoginAuthentication);

export { WrappedLoginAuthentication };
export default WrappedLoginAuthentication;
