import {
  takeLatest,
  takeEvery,
  put,
  getContext,
  call /* , select */,
  select,
} from 'redux-saga/effects';
import { message } from 'antd';
// import { v4 as uuid } from 'uuid';
// import slug from 'slug';
import { PIPObject } from '@api/pipClient';
import doCreatePipClient from '@redux/doCreatePipClient';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { selectAppDetails } from '@organisation/redux/selectors';
import { IAppDetails } from '@organisation/redux/types';
// import { inviteDashboardUser } from '@redux/dashboardUsers/sagas';
// import { selectAppDetails } from '@organisation/redux/selectors';ß
import i18n from '../../i18n';
import {
  loadHospitals,
  loadHospitalsSuccess,
  loadHospitalsFailed,
  IUpdateHospital,
  updateHospitalSuccess,
  updateHospitalFailed,
  // createHospitalFailed,
  // createHospitalSuccess,
  // CreateHospital,
  EditHospital,
  editHospitalFailed,
  editHospitalSuccess,
  loadHospital,
  loadHospitalSuccess,
  loadHospitalFailed,
} from './actions';
import {
  IHospital,
  FETCH_HOSPITALS,
  // IHospitalInvites,
  // CREATE_HOSPITAL,
  UPDATE_HOSPITAL,
  EDIT_HOSPITAL,
  ISolutionConfiguration,
} from './types';
import { doGetHospitalObjectType, parseSolutionConfigurationDetails } from './utils';
import doEnsurePipObjectType from '@redux/doEnsurePipObjectType';
import doCreateIDMService from '@redux/doCreateIDMService';
import doCreateOrganisationDirectoryClient from '@redux/doCreateOrganisationDirectoryClient';
import { HospitalNotFoundError } from './errors';

export default function* root() {
  yield takeLatest(FETCH_HOSPITALS, doFetchHospitals);
  yield takeEvery('hospital/fetch', doFetchHospital);
  // yield takeEvery(CREATE_HOSPITAL, doCreateHospital);
  yield takeLatest(UPDATE_HOSPITAL, doUpdateHospital);
  yield takeEvery(EDIT_HOSPITAL, doEditHospital);
}

export function* doFetchHospitals(): any {
  try {
    yield put(loadHospitals());

    const service = yield call(doCreateIDMService);

    const [solution] = yield call(service.solutions);

    const hospitals = solution.configurations.map((solutionConfiguration: ISolutionConfiguration) =>
      parseSolutionConfigurationDetails(solutionConfiguration),
    );

    yield put(loadHospitalsSuccess(hospitals));
  } catch (err) {
    console.error(err);
    yield put(loadHospitalsFailed());
  }
}

function* doFetchHospital(): any {
  try {
    yield put(loadHospital());

    const { organisationSlug } = yield select(selectAppDetails);

    const client = yield call(doCreateOrganisationDirectoryClient);
    let data = null;
    data = yield call(client.getOrganisation, organisationSlug);
    if (data) {
      const hospital = { ...data };
      if (!hospital.name) {
        hospital.name = hospital.organisation_name;
      }
      yield put(loadHospitalSuccess(hospital));
      return;
    }

    yield put(loadHospitalFailed());
  } catch (err) {
    console.error(err);
    yield put(loadHospitalFailed());
  }
}

function* doUpdateHospital({ payload: { hospital: editedHospital } }: IUpdateHospital): any {
  try {
    const objectType = yield call(doGetHospitalObjectType);
    const history = yield getContext('history');
    const pipClient = yield call(doCreatePipClient);
    const { appToken }: IAppDetails = yield select(selectAppDetails);

    yield call(doEnsurePipObjectType, pipClient, objectType, appToken);

    const [latest]: PIPObject<{ hospital: IHospital }>[] = yield call(
      pipClient.getObjectsForType,
      objectType,
      'latest',
    );

    let hospital = latest?.json.hospital;

    if (!hospital) {
      const currentDashboardUser = yield select(selectCurrentDashboardUser);
      hospital = {
        admin: {
          id: '',
          name: '',
          email: '',
        },
        contactDetails: {},
        content: [],
        defaultConnectionCode: '',
        country: 'Australia',
        created: new Date().toISOString(),
        language: 'en',
        name: currentDashboardUser.profile.hospitalName,
        slug: currentDashboardUser.profile.hospitalSlug,
        socialMedia: [],
        uuid: currentDashboardUser.profile.hospitalId,
      };
    }

    const updatedHospital = {
      ...hospital,
      ...editedHospital,
    };

    yield call(pipClient.createObject, objectType, { hospital: updatedHospital });

    yield put(updateHospitalSuccess(updatedHospital));
    yield call(history.goBack);
    yield call(message.success, i18n.t('hospitals:EditHospitalDetails.successMessage'));
  } catch (err) {
    console.error(err);
    yield put(updateHospitalFailed());
    yield call(message.error, i18n.t('hospitals:EditHospitalDetails.failedMessage'));
  }
}

export function* doEditHospital({ payload: { id, hospital, editOnly } }: EditHospital): any {
  try {
    const objectType = yield call(doGetHospitalObjectType);
    const history = yield getContext('history');
    const pipClient = yield call(doCreatePipClient);

    let [hospitalObject]: { json: { hospital: IHospital } }[] = yield call(
      pipClient.getObjectsForType,
      objectType,
      'latest',
    );

    if (hospitalObject) {
      if (hospital.hospitalName) hospitalObject.json.hospital.name = hospital.hospitalName;
      if (hospital.content) hospitalObject.json.hospital.content = hospital.content;

      yield call(pipClient.createObject, objectType, hospitalObject.json);

      yield put(editHospitalSuccess(hospitalObject.json.hospital));

      // TODO: this needs to be reworked so navigation and success and error messaging is done from the UI code
      if (!editOnly) {
        yield call(history.goBack);
        yield call(message.success, i18n.t('hospitals:Wizard.editSuccess'));
      }
      return;
    }

    throw new HospitalNotFoundError();
  } catch (err) {
    console.error(err);
    yield put(editHospitalFailed());
    yield call(message.error, i18n.t('hospitals:Wizard.editFailed'));
  }
}
