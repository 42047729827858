import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchableTable from '@components/SearchableTable';
import { selectAppUser } from '@redux/appUsers/reducers';
import { fetchForms } from '@redux/forms/actions';
import {
  fetchAppUserJourneys,
  fetchAppUsersPathways,
} from '../../../redux/appUserPathways/actions';
import {
  selectJourneysForAppUser,
  selectPathwaysForAppUserWithOriginals,
} from '../../../redux/appUserPathways/reducers';
import { selectAppUserResultFormsLoading } from '@redux/appUsers/reducers';
import { fetchAppUsersForms } from '@redux/appUsers/actions';
import useContentRefs from '@hooks/useContentRefs';
import { useRouteMatch } from 'react-router-dom';
import createColumns from './createColumns';
import { getBasicContentDetails } from '../../../../utils/contentDetails';

function JourneyTimeline({ appUserId, journeyId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [appUserLoading, appUser] = useSelector(selectAppUser(appUserId));
  const [journeysLoading, journeys] = useSelector(selectJourneysForAppUser(appUser));
  const [appUserPathwaysLoading, appUserPathways] = useSelector(
    selectPathwaysForAppUserWithOriginals(appUser),
  );

  const appUserResultFormsLoading = useSelector(selectAppUserResultFormsLoading);
  const match = useRouteMatch();

  const pathwayDataById = useMemo(() => {
    const pathwaysForJourney =
      appUserPathways?.filter(
        ({ journeyId: pathwayJourneyId }) => pathwayJourneyId === journeyId,
      ) || [];

    return pathwaysForJourney.reduce(
      (byId, pathway) => ({
        ...byId,
        ...(pathway.originalPathway
          ? {
              [pathway.id]: {
                name: pathway.originalPathway?.name,
                originalId: pathway.originalPathway?.id,
              },
            }
          : {}),
      }),
      {},
    );
  }, [appUserPathways, journeyId]);

  const triggerTypeToTranslatedName = useMemo(
    () => ({
      FORM_SUBMISSION: t('cards:JourneyTimeline.triggers.form'),
      RULE_EXECUTION: t('cards:JourneyTimeline.triggers.rule'),
      STAGE_TRANSITION: t('cards:JourneyTimeline.triggers.stage'),
    }),
    [t],
  );

  const filterFunc = useCallback(
    (value, comparator) => {
      return (
        comparator(pathwayDataById[value.data.pathway_id]?.name || '') ||
        comparator(triggerTypeToTranslatedName[value.type] || '')
      );
    },
    [pathwayDataById, triggerTypeToTranslatedName],
  );

  const journey = useMemo(() => (journeys || []).find(({ id }) => id === journeyId), [
    journeys,
    journeyId,
  ]);

  const contentRefs = journey?.entries.reduce((acc, entry) => {
    if (entry.data.content || entry.data.rule_what_details?.content)
      acc.push(entry.data.content || entry.data.rule_what_details?.content);
    return acc;
  }, []);

  const contentItems = useContentRefs(contentRefs);
  const contentDetails = Object.entries(contentItems).reduce((acc, [key, value]) => {
    acc[key] = getBasicContentDetails(value);
    return acc;
  }, {});

  useEffect(() => {
    dispatch(fetchAppUserJourneys(appUserId));
    dispatch(fetchAppUsersPathways());
    dispatch(fetchForms());
    dispatch(fetchAppUsersForms(appUserId));
  }, [dispatch, appUserId]);

  const columns = useMemo(
    () => createColumns(t, match?.params.id, pathwayDataById, contentDetails),
    [t, match, pathwayDataById, contentDetails],
  );

  const loading =
    appUserLoading || journeysLoading || appUserPathwaysLoading || appUserResultFormsLoading;

  return (
    <SearchableTable
      columns={columns}
      dataSource={journey?.entries || []}
      filterFunc={filterFunc}
      loading={loading}
      title={t('cards:JourneyTimeline.title')}
      onRowClick={() => {}}
    />
  );
}

export default JourneyTimeline;
