import i18n from '../../i18n';
import { IAppUser, IUISUser, IUISUserProfile } from './types';

export function mapUISUserToAppUser(uisUser: IUISUser): IAppUser {
  const appUser = {
    invitationCodes: uisUser.codes,
    address: uisUser.profile.address,
    city: uisUser.profile.city,
    dateOfBirth: uisUser.profile.dateOfBirth,
    doctor: uisUser.profile.doctor || i18n.t('patients:unknownValue'),
    email: uisUser.profile.email || i18n.t('patients:unknownValue'),
    firstName: uisUser.profile.firstName,
    hospital: uisUser.profile.hospital || i18n.t('patients:unknownValue'),
    hospitalId: uisUser.profile.hospitalId,
    hospitalSlug: uisUser.profile.hospitalSlug,
    id: uisUser.uuid,
    ids: uisUser.ids.reduce((prev, { pip, uuid }) => ({ ...prev, [pip]: uuid }), {}),
    isActive: !uisUser.disabled,
    language: uisUser.profile.language,
    lastName: uisUser.profile.lastName,
    phone: uisUser.profile.phone,
    postcode: uisUser.profile.postcode,
    emailVerified: uisUser.email_verified,
    phoneVerified: uisUser.phone_verified,
    userUrl: uisUser.user,
    url: uisUser.url,
    uuid: uisUser.uuid,
    sex: uisUser.profile.sex || '',
    title: uisUser.profile.title || '',
    displayName:
      uisUser.profile.firstName || uisUser.profile.lastName
        ? `${uisUser.profile.firstName} ${uisUser.profile.lastName}`.trim()
        : uisUser.profile.email || uisUser.uuid,
    _pep: uisUser.profile._pep,
    files: uisUser.profile.files,
    created: uisUser.created,
  };

  return appUser;
}

export const buildProfileFromAppUser = (appUser: IAppUser): IUISUserProfile => ({
  address: appUser.address,
  city: appUser.city,
  dateOfBirth: appUser.dateOfBirth,
  doctor: appUser.doctor,
  email: appUser.email,
  firstName: appUser.firstName,
  hospitalId: appUser.hospitalId,
  language: appUser.language,
  lastName: appUser.lastName,
  phone: appUser.phone,
  postcode: appUser.postcode,
  sex: appUser.sex,
  title: appUser.title,
  _pep: appUser._pep,
  files: appUser.files,
});

export const createRegistrationCode = (length = 6) => `I${_createRandomCode(length - 1)}`;

const _createRandomCode = (length: number) =>
  Math.round(Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))
    .toString(36)
    .slice(1)
    .toUpperCase();
