import { combineReducers } from 'redux';
import { createUniqueArray } from '@utils';
import {
  LOAD_DOCUMENTS,
  DOCUMENTS_LOADED,
  DOCUMENT_VERSIONS_LOADED,
  DOCUMENT_PAGES_LOADED,
  GRANT_GROUP_DOCUMENTS_ACCESS,
  REVOKE_GROUP_DOCUMENTS_ACCESS,
  GRANT_INDIVIDUAL_DOCUMENTS_ACCESS,
  REVOKE_INDIVIDUAL_DOCUMENTS_ACCESS,
  IDocument,
  IDocumentVersion,
  IDocumentPage,
  LOAD_DOCUMENTS_FAILED,
  PUBLISH_DOCUMENTS,
  UNPUBLISH_DOCUMENTS,
  FETCH_DOCUMENT_PAGES,
} from './types';
import {
  IDocumentsLoaded,
  IDocumentVersionsLoaded,
  ILoadDocuments,
  IPublishDocuments,
  IUnpublishDocuments,
  IDocumentPagesLoaded,
  IGrantDocumentsGroupAccess,
  IRevokeDocumentsGroupAccess,
  IGrantDocumentsIndividualAccess,
  IRevokeDocumentsIndividualAccess,
  ICreateDocumentSuccess,
  ICreateDocument,
  ICreateDocumentFailed,
  IEditDocument,
  IEditDocumentFailed,
  IEditDocumentSuccess,
  ILoadDocumentsFailed,
  IDeleteDocumentsSuccess,
  IDeleteDocumentsFailed,
  IDeleteDocuments,
  IDeleteDocument,
  IChangePublishedStatus,
  IPublishDocumentsSuccess,
  IPublishDocumentsFailed,
  IUnpublishDocumentsFailed,
  IUnpublishDocumentsSuccess,
  ICreateVideoSuccess,
  ICreateVideo,
  IEditVideo,
  IEditVideoSuccess,
  IDocumentPreviewLoaded,
  IGetDocumentPreview,
  IDocumentPreviewFailed,
  IClearDocumentPreview,
  IFetchDocumentPages,
} from './actions';
import { IState } from '../reducer';
import { ILoadContentSuccess } from '@redux/core/actions';

const creating = (
  state = false,
  action:
    | ICreateDocumentSuccess
    | ICreateDocument
    | ICreateDocumentFailed
    | ICreateVideoSuccess
    | ICreateVideo,
) => {
  switch (action.type) {
    case 'documents/create':
    case 'videos/create':
      return true;
    case 'documents/createFailed':
    case 'documents/createSuccess':
    case 'videos/createSuccess':
      return false;
    default:
      return state;
  }
};

const deleting = (
  state = false,
  action: IDeleteDocumentsSuccess | IDeleteDocumentsFailed | IDeleteDocuments | IDeleteDocument,
) => {
  switch (action.type) {
    case 'documents/delete':
    case 'documents/deleteOne':
      return true;
    case 'documents/deleteFailed':
    case 'documents/deleteSuccess':
      return false;
    default:
      return state;
  }
};

const editing = (
  state = false,
  action:
    | IEditDocument
    | IEditDocumentFailed
    | IEditDocumentSuccess
    | IEditVideo
    | IEditVideoSuccess,
) => {
  switch (action.type) {
    case 'documents/edit':
    case 'videos/edit':
      return true;
    case 'documents/editFailed':
    case 'documents/editSuccess':
    case 'videos/editSuccess':
      return false;
    default:
      return state;
  }
};

function fetchingPages(state = false, action: IFetchDocumentPages | IDocumentPagesLoaded) {
  switch (action.type) {
    case FETCH_DOCUMENT_PAGES:
      return true;
    case DOCUMENT_PAGES_LOADED:
      return false;
    default:
      return state;
  }
}

const loading = (
  state = false,
  action:
    | ILoadDocuments
    | IDocumentsLoaded
    | ILoadDocumentsFailed
    | IDeleteDocuments
    | IDeleteDocumentsSuccess
    | IDeleteDocumentsFailed,
): boolean => {
  switch (action.type) {
    // case 'documents/delete':
    case LOAD_DOCUMENTS:
      return true;
    case DOCUMENTS_LOADED:
    case LOAD_DOCUMENTS_FAILED:
      // case 'documents/deleteFailed':
      // case 'documents/deleteSuccess':
      return false;
    default:
      return state;
  }
};

const list = (
  state: number[] = [],
  action: IDocumentsLoaded | IDeleteDocumentsSuccess | ICreateDocumentSuccess | ICreateVideoSuccess,
): number[] => {
  switch (action.type) {
    case DOCUMENTS_LOADED:
      return action.payload.documents.map(doc => doc.id);
    case 'documents/createSuccess':
    case 'videos/createSuccess':
      return [action.payload.createdDocument.id, ...state];
    case 'documents/deleteSuccess':
      return state.filter(docId => !action.payload.documentIds.includes(docId));
    default:
      return state;
  }
};

export interface IByIdState {
  [key: string]: IDocument;
}

const byId = (
  state: IByIdState = {},
  action:
    | IDocumentsLoaded
    | IPublishDocuments
    | IUnpublishDocuments
    | ICreateDocumentSuccess
    | IEditDocumentSuccess
    | ICreateVideoSuccess
    | IEditVideoSuccess
    | ILoadContentSuccess
    | IChangePublishedStatus,
): IByIdState => {
  switch (action.type) {
    case 'documents/createSuccess':
    case 'videos/createSuccess':
      return {
        ...state,
        [action.payload.createdDocument.id]: action.payload.createdDocument,
      };
    case 'documents/editSuccess':
    case 'videos/editSuccess':
      return {
        ...state,
        [action.payload.documentId]: action.payload.editedDocument,
      };
    case DOCUMENTS_LOADED:
      // Maintain existing documents in the reducer for documents which are not from this organisation.
      return {
        ...state,
        ...Object.fromEntries(action.payload.documents.map(doc => [doc.id, doc])),
      };
    case 'core/load-content-success':
      return {
        ...state,
        // Documents use their numeric ids instead of product id here.
        ...Object.fromEntries(Object.values(action.payload.documents).map(doc => [doc.id, doc])),
      };
    case 'documents/changePublishStatus':
      return {
        ...state,
        [action.payload.documentId]: {
          ...state[action.payload.documentId],
          published: action.payload.isPublished,
        },
      };
    default:
      return state;
  }
};

const contentIdToId = (
  state: { [key: string]: number } = {},
  action:
    | IDocumentsLoaded
    | IPublishDocuments
    | IUnpublishDocuments
    | ICreateDocumentSuccess
    | IEditDocumentSuccess
    | ICreateVideoSuccess
    | IEditVideoSuccess
    | ILoadContentSuccess
    | IChangePublishedStatus,
): { [key: string]: number } => {
  switch (action.type) {
    case 'documents/createSuccess':
    case 'videos/createSuccess':
      return {
        ...state,
        [action.payload.createdDocument.product_id]: action.payload.createdDocument.id,
      };
    case DOCUMENTS_LOADED:
      return {
        ...state,
        ...Object.fromEntries(action.payload.documents.map(doc => [doc.product_id, doc.id])),
      };
    case 'core/load-content-success':
      return {
        ...state,
        // Documents use their numeric ids instead of product id here.
        ...Object.fromEntries(
          Object.values(action.payload.documents).map(doc => [doc.product_id, doc.id]),
        ),
      };
    default:
      return state;
  }
};

export interface IDocumentVersionsState {
  [key: string]: IDocumentVersion;
}
const versions = (
  state: IDocumentVersionsState = {},
  action: IDocumentVersionsLoaded,
): IDocumentVersionsState => {
  switch (action.type) {
    case DOCUMENT_VERSIONS_LOADED:
      return {
        ...state,
        [action.payload.documentId]: action.payload.versions,
      };
    default:
      return state;
  }
};

export interface IDocumentPagesState {
  [key: string]: { expires: number; pages: IDocumentPage[] };
}
const pages = (
  state: IDocumentPagesState = {},
  action: IDocumentPagesLoaded,
): IDocumentPagesState => {
  switch (action.type) {
    case DOCUMENT_PAGES_LOADED:
      if (action.payload.status === 'cached') {
        return state;
      }
      return {
        ...state,
        [action.payload.documentId]: {
          expires: Date.now() + 1000 * 60 * 50, // 50 minutes
          pages: action.payload.pages,
        },
      };
    default:
      return state;
  }
};

export interface IPermissionsState {
  groups: {
    [key: string]: string[];
  };
  individuals: {
    [key: string]: string[];
  };
}
const permissions = (
  state: IPermissionsState = {
    groups: {},
    individuals: {},
  },
  action:
    | IGrantDocumentsGroupAccess
    | IRevokeDocumentsGroupAccess
    | IGrantDocumentsIndividualAccess
    | IRevokeDocumentsIndividualAccess,
): IPermissionsState => {
  switch (action.type) {
    case GRANT_GROUP_DOCUMENTS_ACCESS:
      return {
        ...state,
        groups: action.payload.documentIds.reduce(
          (
            acc: {
              [key: string]: string[];
            },
            docId: string,
          ) => ({
            ...acc,
            [docId]: createUniqueArray([
              ...(state.groups[docId] || []),
              ...action.payload.groupIds,
            ]),
          }),
          state.groups,
        ),
      };
    case REVOKE_GROUP_DOCUMENTS_ACCESS: {
      const filteredGroups = Object.keys(state.groups).reduce(
        (acc, docId) =>
          action.payload.documentIds.includes(docId)
            ? {
                ...acc,
                [docId]: state.groups[docId].filter(
                  groupId => !action.payload.groupIds.includes(groupId),
                ),
              }
            : acc,
        state.groups,
      );

      return {
        ...state,
        groups: filteredGroups,
      };
    }
    case GRANT_INDIVIDUAL_DOCUMENTS_ACCESS:
      return {
        ...state,
        individuals: action.payload.documentIds.reduce(
          (acc, docId) => ({
            ...acc,
            [docId]: createUniqueArray([
              ...(state.individuals[docId] || []),
              ...action.payload.appUserIds,
            ]),
          }),
          state.individuals,
        ),
      };
    case REVOKE_INDIVIDUAL_DOCUMENTS_ACCESS: {
      const filteredIndividuals = Object.keys(state.individuals).reduce(
        (acc, docId) =>
          action.payload.documentIds.includes(docId)
            ? {
                ...acc,
                [docId]: state.individuals[docId].filter(
                  individualId => !action.payload.appUserIds.includes(individualId),
                ),
              }
            : acc,
        state.individuals,
      );

      return {
        ...state,
        individuals: filteredIndividuals,
      };
    }
    default:
      return state;
  }
};

const publishing = (
  state = false,
  action:
    | IPublishDocuments
    | IPublishDocumentsSuccess
    | IPublishDocumentsFailed
    | IUnpublishDocuments
    | IUnpublishDocumentsFailed
    | IUnpublishDocumentsSuccess,
) => {
  switch (action.type) {
    case PUBLISH_DOCUMENTS:
    case UNPUBLISH_DOCUMENTS:
      return true;
    case 'documents/publishDocumentsFailed':
    case 'documents/publishDocumentsSuccess':
    case 'documents/unpublishDocumentsFailed':
    case 'documents/unpublishDocumentsSuccess':
      return false;
    default:
      return state;
  }
};

function previewPages(
  state: string[] = [],
  action: IDocumentPreviewLoaded | IClearDocumentPreview,
) {
  switch (action.type) {
    case 'documents/preview-loaded':
      return action.payload.pages;
    case 'document/clear-preview':
      return [];
    default:
      return state;
  }
}

function previewLoading(
  state: boolean = false,
  action: IGetDocumentPreview | IDocumentPreviewLoaded | IDocumentPreviewFailed,
) {
  switch (action.type) {
    case 'documents/get-preview':
      return true;
    case 'documents/preview-failed':
    case 'documents/preview-loaded':
      return false;
    default:
      return state;
  }
}

const preview = combineReducers({ loading: previewLoading, pages: previewPages });

export default combineReducers({
  creating,
  deleting,
  editing,
  fetchingPages,
  loading,
  list,
  byId,
  contentIdToId,
  versions,
  pages,
  permissions,
  preview,
  publishing,
});

export const selectDocumentCreating = (state: IState) => state.documents.creating;
export const selectDocumentDeleting = (state: IState) => [state.documents.deleting];
export const selectDocumentEditing = (state: IState) => state.documents.editing;
export const selectDocumentPublishing = (state: IState) => state.documents.publishing;

export const selectDocuments = (state: IState) => [
  state.documents.loading,
  state.documents.list.map(docId => state.documents.byId[docId]),
];

export const selectNonVideoDocuments = (state: IState) => {
  const allDocuments = state.documents.list.map(docId => state.documents.byId[docId]);
  const documents = allDocuments.filter(
    doc => !doc.metadata?.tags?.some(tag => tag.term === 'VIDEO'),
  );

  return [state.documents.loading, documents];
};

export const selectVideoDocuments = (state: IState) => {
  const allDocuments = state.documents.list.map(docId => state.documents.byId[docId]);
  const videos = allDocuments.filter(doc => doc.metadata?.tags?.some(tag => tag.term === 'VIDEO'));

  return [state.documents.loading, videos];
};

export const selectDocument = (documentId: string) => (state: IState) => [
  state.documents.loading,
  state.documents.byId[documentId],
];

export const selectDocumentVersions = (documentId: string) => (state: IState) =>
  state.documents.versions[documentId];

export const selectDocumentPages = (documentId: string) => (state: IState) => [
  state.documents.fetchingPages,
  state.documents.pages[documentId]?.pages,
];

export const selectDocumentPermissions = (documentId: string) => (state: IState) => [
  state.documents.permissions.groups[documentId],
  state.documents.permissions.individuals[documentId],
];

export const selectDocumentPublishedStatus = (documentId: string) => (state: IState) =>
  state.documents.byId[documentId]?.published;

export const selectDocumentPreview = (state: IState) => state.documents.preview;
